export const anges = [
    {
        "numero": "1",
        "nom": "Vehuiah",
        "qualites": "Volonté Divine ; Apporte le Feu Créateur Primordial ; Capacité d'entreprendre, de commencer ; Succès pour toute nouvelle création ; Guide vers un travail inédit et dans un domaine d'avant-garde ; Donne l'exemple, sert de modèle, est un leader ; Aide à sortir de la confusion et de l'impasse ; Regain d’énergie qui permet de guérir la maladie, le mal-être et la dépression ; Abondance d'énergie, courage, audace, bravoure ; Aime comme si c'était la première fois ; Compréhension de sa propre valeur et de la valeur de l’autre, ainsi que de l’importance de l’individualité et de l’intimité ; Faculté de concentration, de focalisation sur un objectif ; Aide à comprendre le succès et le leadership véritables ",
        "distorsions": "Impose sa volonté, tendance à forcer, contrecarrer ou défier le Destin - Têtu, acharné, autoritaire, imposant - Déclenche la colère, la turbulence - Intervient dans des affaires qui finiront mal - Fonce sans réfléchir, passion dangereuse - Réactions excessives, impétuosité, situations violentes, destruction de l’entourage - Manque de dynamisme et de volonté - Incapacité à déterminer son orientation ou la direction à prendre ",
        "situations": "Audace ; Dos ; Égocentrisme, égoïsme ; Fatique, fatigue chronique ; Pieds ; Jambes ; Nervosité, tension nerveuse, l'excitabilité ; Pessimisme ; Entorse ; Ténacité ; Entreprendre ; Affaiblissement ; Volonté ",
        "calendrierPhysique": "21/03 - 25/03",
        "calendrierEmotionnel": "09/01 00:00-23:59 ; 21/03 00:00 - 23:59 03/06 00:00 - 23:59 17/08 00:00 - 23:59 30/10 00:00 - 23:59 ",
        "calendrierIntellectuel": "00:00 - 00:19"
    },
    {
        "numero": "2",
        "nom": "Jeliel",
        "qualites": "Amour, sagesse ; Touche la vie de couple et la relation à l’autre ; Capacité de concrétiser et de consolider n’importe quelle réalité ; Association providentielle ; Accorde solidité, tranquillité et fécondité ; Accorde la fidélité du conjoint ; Règle tout litige et tout conflit ; Altruiste, cherche à manifester l’amour partout ; Médiateur, conciliateur ; Unifie les principes masculin et féminin ; Convivialité, vie harmonieuse ; Verbe puissant qui inspire le calme ; Aide à calmer les révolutions intérieures ; Capacité de persuasion, lucidité dans l’analyse théorique ",
        "distorsions": "Manque d’amour, absence de sagesse - Difficultés dans la vie de couple et dans la relation avec les autres - Mœurs et comportements pervers, corruption - Mauvaises associations - Conflit perpétuel, querelle, oppression, tyrannie - Désaccord, séparation, divorce - Problèmes avec la sexualité et la relation intime - Célibat égoïste, rejette les enfants par égoïsme - Difficulté à rencontrer un conjoint ",
        "situations": "Lien, union ; Divorce ; Mariage ; Médiation ; Union, lien  ",
        "calendrierPhysique": "26/03 - 30/03",
        "calendrierEmotionnel": "10/01 00:00 - 23:59 22/03 00:00 - 23:59 04 /06 00:00 - 23:59 18 /08 00:00 - 23:59 19 /08 00:00 - 12:00 31 /10 00:00 - 23:59",
        "calendrierIntellectuel": "00:20 - 00:39"
    },
    {
        "numero": "3",
        "nom": "Sitael",
        "qualites": "Construction ; Maître bâtisseur tant à l’intérieur qu’à l’extérieur ; Haute Science ; Confère le pouvoir d’expansion, la capacité de tout faire fructifier ; Planificateur, grand stratège, doué d’un sens pratique ; Administrateur honnête et intègre ; Soutien qui permet de vaincre toute difficulté, toute adversité ; Capacité de concevoir un enfant, un projet ; Aide à prendre conscience de nos erreurs et à transformer nos karmas ; Noblesse, magnanimité, générosité, clémence ; Fidèle à la parole donnée, pacificateur ; Emploi avec d’importantes responsabilités ; Architecte et ingénieur au service du Divin ; Don pour négocier, enthousiasme ; Notoriété sociale et politique ",
        "distorsions": "Destruction, écroulement des structures, période défavorable, ruine - Avidité, excès, stratégie démoniaque - Erreur de préparation, de planification et d’appréciation - Difficulté à concevoir un enfant, un projet - Agressivité, ingratitude, vantardise - Hypocrisie, emphase mise sur la façade, manque d’authenticité - Personne qui ne tient pas ses promesses ou qui n’est pas fidèle à sa parole ",
        "situations": "Architecture, architecte ; Dos ; Os ; Conspiration ; Efficacité ; mains ; Lombaires ; Préparation (sur tous les plans) ; Vertèbres ",
        "calendrierPhysique": "31/03 - 04/04",
        "calendrierEmotionnel": "11/01 00:00 - 23:59 23/03 00:00 - 23:59 05 /06 00:00 - 23:59 19 /08 12:00 - 23:59 20 /08 00:00 - 23:59 01 /11 00:00 - 23:59",
        "calendrierIntellectuel": "00:40 - 00:59"
    },
    {
        "numero": "4",
        "nom": "Elemiah",
        "qualites": "Pouvoir Divin ; Haute Science ; Autorité juste, équitable, impartiale ; Redressement, découverte d’un nouveau chemin ; Force qui aide à passer à l’action, capacité de décision ; Participation à la création du Destin ; Étude et révélation du plan de vie ; Découverte de l’orientation professionnelle ; Initiative, entreprise, engagement ; Optimisme, fin d’une période difficile ; Disparition de l’agitation et des tourments ; Permet d’identifier ceux qui nous ont trahis pour faire la paix avec eux ",
        "distorsions": "Pouvoir diabolique orienté vers la satisfaction des besoins personnels - Inertie, tendances destructrices - Échec professionnel, faillite, revers, période de destruction - Pessimisme, tourments, découvertes dangereuses - Trahison, existence de traîtres intérieurs - Avidité et abus de pouvoir - Domination par les autres - Épuisement, à bout de ressources - Sentiments de supériorité et d’infériorité ",
        "situations": "Autoritarisme ; Trahison ; Réparation, réparer ",
        "calendrierPhysique": "05/04 - 09/04",
        "calendrierEmotionnel": "12 /01 00:00 - 23:59 24/03 00:00 - 23:59 06 /06 00:00 - 23:59 21 /08 00:00 - 23:59 02 /11 00:00 - 23:59",
        "calendrierIntellectuel": "01:00 - 01:19"
    },
    {
        "numero": "5",
        "nom": "Mahasiah",
        "qualites": "Rectification des erreurs ; Réforme, rétablit l’Ordre Divin ; Redresse ce qui pousse de travers avant la matérialisation ; Facilite l’apprentissage ; Capacité de vivre en paix et de jouir des choses simples et naturelles ; Réussite des examens ; Entrée dans une école initiatique ; Analyse des rêves, étude du langage symbolique ; Décodage des signes reçus dans la vie quotidienne ; Aptitudes pour la Science Initiatique ; Amélioration du caractère, existence belle et heureuse ; Facilité dans l’apprentissage des langues ",
        "distorsions": "Difficulté à rectifier, réparer, reconnaître et regretter ses erreurs ou à pardonner les erreurs commises par autrui - Tendance à vouloir se venger, rancune, préjugés, arrogance - Malfaisant, pernicieux - Ignorance - Libertinage, abus sexuels - Dénégation de ses propres erreurs, mauvais caractère, difficile à vivre - Difficulté à apprendre, mauvais choix, autoritarisme - Adhésion à un mouvement spirituel pour fuir la réalité - Santé précaire ",
        "situations": "Équité ; Effacer ; Examens, études ; Apprentissage des Langues ; Rectifier ; Examen ",
        "calendrierPhysique": "10/04 - 14/04",
        "calendrierEmotionnel": "13 /01 00:00 - 23:59 25/03 00:00 - 23:59 07 /06 00:00 - 23:59 22 /08 00:00 - 23:59 03 /11 00:00 - 23:59",
        "calendrierIntellectuel": "01:20 - 01:39"
    },
    {
        "numero": "6",
        "nom": "Lelahel",
        "qualites": "Lumière Divine qui guérit tout (Lumière d’Amour) ; Lucidité, clarté de compréhension ; Renommée, bonheur, fortune ; Embellissement, beauté naturelle ; Miroir de l'âme ; Art de bien s’exprimer dans la société ; Célébrité par le talent et les réalisations ; Artiste",
        "distorsions": "Manque d’amour empêchant la guérison et la compréhension - Ambition - Porte des masques, personnalités multiples - Beauté extérieure seulement - Se pense indispensable - Sentiments de supériorité et d’infériorité - Dépenses inutiles, gaspillage - Tendance à tout prendre pour acquis - Fortune acquise illicitement (argent sale) - Arrivisme, orgueil - Axé uniquement sur l’aspect matériel des choses et des êtres - Utilise son charme à des fins personnelles et égoïstes - Vit au-dessus de ses moyens - Situation instable ",
        "situations": "Embellir ; Beauté ; Célébrité, renommée ; Ténèbres, forces des ténèbres ; Embellir ; Lumière ; Obscurité, ténèbres ",
        "calendrierPhysique": "15/04 - 20/04",
        "calendrierEmotionnel": "14 /01 00:00 - 23:59 26/03 00:00 - 23:59 08 /06 00:00 - 23:59 23 /08 00:00 - 23:59 04 /11 00:00 - 23:59",
        "calendrierIntellectuel": "01:40 - 01:59"
    },
    {
        "numero": "7",
        "nom": "Achaiah",
        "qualites": "Patience ; Découverte du rôle de la patience dans le processus de Création de l’Univers ; Exploration des dimensions intérieures, aide à découvrir la Vérité ; Bonne utilisation des périodes d’attente ; Facilité dans l’exécution de travaux difficiles ; Force bénéfique à l’utilisation des ordinateurs et à la programmation ; Faculté d’introspection et discernement permettant de découvrir les aspects cachés, occultés ; Propagateur de la Lumière (de la Connaissance) ; Facilite la diffusion médiatique par les ordinateurs, la télévision, la radio, la presse et l’édition ; Soutien pour réussir les examens et résoudre les problèmes difficiles, donne le goût de s’instruire ; Aide à découvrir les secrets cachés, à trouver des solutions inédites ",
        "distorsions": "Impatience, révolte, résignation - Paresse, négligence, insouciance, ignorance - N’a aucune envie d’apprendre, n’étudie pas - Échec aux examens, désarroi face aux situations nouvelles - Paralysie face à l’adversité - Problèmes avec les ordinateurs et la programmation - Écarté des postes de commande - Manipulation médiatique, recherche de gloire personnelle - Incompréhension - Ne tient pas ses promesses ",
        "situations": "Changement ; Édition ; Impatience ; Paresse ; Patience ; Impression ; Enregistrement ; Enregistrer ; Télévision ",
        "calendrierPhysique": "21/04 - 25/04",
        "calendrierEmotionnel": "15 /01 00:00 - 23:59 27/03 00:00 - 23:59 09 /06 00:00 - 23:59 24 /08 00:00 - 23:59 05 /11 00:00 - 23:59",
        "calendrierIntellectuel": "02:00 - 02:19"
    },
    {
        "numero": "8",
        "nom": "Cahetel",
        "qualites": "Bénédiction Divine ; Gratitude ; Matérialise la Volonté Divine ; Enfantement, accouchement ; Réussite facile, progrès, aide à changer de mode de vie ; Grande capacité de travail, vie active ; Richesse matérielle ; Terres fertiles, récoltes abondantes, nourriture pour l’âme ; Harmonie avec les Lois Cosmiques ; Patron des quatre éléments : feu, air, eau, terre ; Libère des mauvais esprits ",
        "distorsions": "Manque de gratitude - Personne excessivement centrée sur elle-même, qui ne suit que ses propres intérêts, comportement prédateur - Échec matériel, ruine - Agit contre le Destin, se rebelle contre son plan de vie et contre le Programme Cosmique, Divin - Activités inutiles et stériles - Volontarisme excessif, rigidité - Despotisme, orgueil, mauvais caractère, blasphème - Fortune utilisée uniquement à des fins matérielles - Pluies torrentielles, inondations, eaux polluées - Climat catastrophique, incendies - Sentiments troubles, agression, transgression - Agit à l’encontre des lois, corruption, écrase les autres ",
        "situations": "Agriculture ; Blasphème ; Bénédiction de l'habitation ; Accouchement ; Catastrophes ; Inondations ; Ouragan ; Pollution ; Grossesse ",
        "calendrierPhysique": "26/04 - 30/04 ",
        "calendrierEmotionnel": "16 /01 00:00 - 23:59 28/03 00:00 - 23:59 10 /06 00:00 - 23:59 25 /08 00:00 - 23:59 06 /11 00:00 - 23:59",
        "calendrierIntellectuel": "02:20 - 02:39"
    },
    {
        "numero": "9",
        "nom": "Haziel",
        "qualites": "Amour Universel ; Miséricorde Divine ; Don du pardon, réconciliation ; Bonne foi ; Confiance, sincérité ; Bonté qui absout tout mal ; Énergie puissante qui transforme tout négativisme ; Appui, soutien, amitié, grâce, faveur des puissants ; Promesse, engagement ; Altruisme, désintéressement ; Pureté de l’enfance ",
        "distorsions": "Difficulté à aimer et à être aimé et/ou absence d’amour - Possessivité, jalousie, passion, peur d’aimer et d’être aimé - Haine, guerre, non-réconciliation - Hypocrite, trompe les autres - Manipule pour obtenir la faveur des puissants - Rancœur, malveillance, hostilité ",
        "situations": "Altruisme ; Amabilité ; Enfant ; Détachement ; Émotionnel: débordement émotionnel ; Reconnu, sentiment de ne pas être reconnu ; Féminité ; Pardon ; Bonté ; Rancune ; Méchanceté ; Miséricorde ; Possessivité ; Rancœur, sentiment de vengeance ; Réconciliation ",
        "calendrierPhysique": "01/05 - 05/05",
        "calendrierEmotionnel": "17 /01 00:00 - 23:59 29/03 00:00 - 23:59 11 /06 00:00 - 23:59 26 /08 00:00 - 23:59 07 /11 00:00 - 23:59",
        "calendrierIntellectuel": "02:40 - 02:59"
    },
    {
        "numero": "10",
        "nom": "Aladiah",
        "qualites": "Grâce Divine qui absout et pardonne toute faute ; Dissout tout karma ; Abondance spirituelle et matérielle ; Innocence ; Réinsertion dans la société ; Grand pouvoir de guérison ; Régénération, santé florissante ; Aide les défavorisés ; Nouveau départ, seconde chance ",
        "distorsions": "Attitudes et comportements générant des problèmes et des difficultés karmiques - Répétition des erreurs et des actes manqués - Spiritualité dangereuse, faux gourou - Gaspillage - Promesses non tenues - Crimes cachés - Déchéance morale - Négligence - Nonchalance, indifférence, mollesse - Mauvaise santé, karma difficile - Boulimie, excès sexuels, luxure - Malfaiteur, personne qui enfreint la loi, prisonnier ",
        "situations": "Grâce ; Aide à résoudre le karma ; Charlatan ; Obésité ; Tentations ",
        "calendrierPhysique": "06/05 - 10/05",
        "calendrierEmotionnel": "18 /01 00:00 - 23:59 30/03 00:00 - 23:59 12 /06 00:00 - 23:59 13 /06 00:00 - 12:00 27 /08 00:00 - 23:59 08 /11 00:00 - 23:59",
        "calendrierIntellectuel": "03:00 - 03:19"
    },
    {
        "numero": "11",
        "nom": "Lauviah",
        "qualites": "Victoire ; Renommée, célébrité, réussite ; Expertise ; Vie de dévouement ; Altruisme, bonté, gentillesse ; Reçoit la lumière de Dieu ; Confiance, enthousiasme, joie ; Réussite des initiations ; Amour exalté pour l'Œuvre Divine ; Entreprises utiles et profitables pour l'humanité ; Peut tout obtenir des grands de ce monde ; Organisation Cosmique ",
        "distorsions": "Difficulté à réussir, à avoir du succès, échec - Joue des rôles pour plaire, manque d’authenticité, hyper positivisme - Focalisation excessive sur la renommée, la célébrité, la notoriété, ou tendance à les rejeter et à se contenter d’une vie médiocre - Vise trop haut ou trop bas - Envie, jalousie, orgueil, calomnie, utilise la ruse pour réussir - Extravagance, ambition, avidité de pouvoir - Matérialisme excessif, veut jouir uniquement des ressources physiques - Débordements émotionnels, dépendance affective - Manque de confiance et/ou difficulté à avoir confiance en autrui - Œuvres perverses - Foudre, réprimande de l’Intelligence Cosmique ",
        "situations": "Célébrité, renommée ; Accusations ; Enthousiasme, manque d'enthousiasme ; Jalousie ; Joie ; Foudre ; Optimisme ; Orgueil ; Calomnie ; Entorse ",
        "calendrierPhysique": "11/05 - 15/05",
        "calendrierEmotionnel": "19 /01 00:00 - 23:5931/03 00:00 - 23:59 13 /06 12:00 - 23:59 14 /06 00:00 - 23:59 28 /08 00:00 - 23:59 09 /11 00:00 - 23:59",
        "calendrierIntellectuel": "03:20 - 03:39"
    },
    {
        "numero": "12",
        "nom": "Hahaiah",
        "qualites": "Refuge, période de calme, de repos ; Méditation, intériorisation, amour de la solitude ; Favorise le sommeil, aide à se ressourcer, à renouveler son énergie et à rétablir l’équilibre entre la vie intime et la vie sociale ; Inspire à prendre soin autant de notre monde intérieur que de notre maison, notre foyer ; Appréciation de la propreté et de l’ordre ; Harmonisation intérieure par la remise en question de soi ; Transforme les attitudes destructrices ; Isole les tendances négatives dans un cercle énergétique ; Examen de la vie personnelle ; Dissolution de l’agressivité ; Facilite l’interprétation des rêves, donne accès aux mystères occultes ; Accorde la paix, protège ; Accroît la médiumnité ; Attitude positive, discrétion ",
        "distorsions": "Tendance à se retirer, à s’isoler, à s’évader, à fuir ou à refuser de faire face à ses responsabilités ; ou comportement hyperactif par lequel l’être cherche à camoufler ses problèmes, ses soucis et insécurités, ses blessures ou difficultés émotionnelles - Indépendance excessive, attitude d’ermite - Période de stress et d’agitation, manque de temps pour soi, difficulté à s’intérioriser et à méditer - Excès ou manque de vie sociale - Comportement asocial - Tendance à entretenir, nourrir, ruminer des problèmes émotionnels ou à refuser de leur faire face, ou à bouder, à faire la tête - Impulsivité, agressivité - Dépendances - Négativisme, indiscrétion - Mensonge, abus de confiance, trahison, amertume, ressentiment, résignation, rancune  - Hallucinations - Supercherie et élucubrations de médiums déséquilibrés - Confusion entre les rêves et la réalité - Phobies : agoraphobie, claustrophobie, etc. ",
        "situations": "Agoraphobie / Angoisse ; Clair audience, clair sentience, clair voyance, télépathie ; Discrétion, subtilité ; Rêves, interprétations des rêves ; Peur ; Hallucinations ; Insomnie ; Phobie / Xénophobie ; Refuge, trouver refuge ; Sommeil ; Subtilité, discrétion ",
        "calendrierPhysique": "16/05 - 20/05",
        "calendrierEmotionnel": "20 /01 00:00 - 23:5901 /04 00:00 - 23:5915 /06 00:00 - 23:5929 /08 00:00 - 23:5910 /11 00:00 - 23:59",
        "calendrierIntellectuel": "03:40 - 03:59"
    },
    {
        "numero": "13",
        "nom": "Iezalel",
        "qualites": "Fidélité ; Réconciliation, affinité ; Facilité d'apprentissage ; Mémoires heureuses ; Amitié, rassemblements ; Fidèle serviteur ; Préparation des rencontres ; Fidèle aux Principes Divins ; Donne forme à l'unité, à l'union ; Complémentarité et équilibre entre le masculin et le féminin ; Ordre, harmonie",
        "distorsions": "Infidélité - Enchaînement, passion - Focalisation excessive sur les besoins personnels et la vie sociale - Veut plaire à tout le monde - Blesse les enfants, détruit le mariage et la famille, séparation, divorce - Engendre des karmas lourds de conséquences - Ignorance, erreur - Esprit limité - Tendance à ne pas tirer de leçons des expériences vécues - Éloignement des êtres aimés - Mensonge, tricherie - Ne souhaite pas apprendre - Influence négative sur les autres et sur les situations",
        "situations": "Adultère ; Trahison ; Fidélité",
        "calendrierPhysique": "21/05 - 25/05",
        "calendrierEmotionnel": "21 /01 00:00 - 23:59 02 /04 00:00 - 23:59 16 /06 00:00 - 23:59 30 /08 00:00 - 23:59 11 /11 00:00 - 23:59",
        "calendrierIntellectuel": "04:00 - 04:19"
    },
    {
        "numero": "14",
        "nom": "Mebahel",
        "qualites": "Engagement ; Aide humanitaire, altruisme ; Devise : Vérité, Liberté, Justice ; Amour inconditionnel ; Inspiration en provenance des Mondes Supérieurs ; Libère les opprimés et les prisonniers ; Aide ceux qui ont perdu l'espoir ; Équité, aime la justesse, l’exactitude, la précision et les choix justes, rétablit l’ordre naturel ; Respect de l’environnement ; Exorcisme ; Médiation, arbitrage ; Abondance, richesse, élévation des sens ",
        "distorsions": "Difficulté à s’engager ou désengagement - Ne tient pas ses promesses, manque à sa parole - Aide les autres de manière excessive ou insuffisante - Sentiment d’être mal aimé ou rejeté - Problèmes avec la vérité et la justice, mensonge, faux témoignage, calomnie - Procès, accusation, captivité, emprisonnement - Malfaiteur, criminel - Usurpation, lutte intérieure, adversité, oppression - Forces démoniaques - Dynamique tyran/victime - S’identifie à la loi et aux conventions sociales - Marche à contre¬courant ",
        "situations": "Altruisme ; Exorcisme ",
        "calendrierPhysique": "26/05 - 31/05",
        "calendrierEmotionnel": "22 /01 00:00 - 23:59 03 /04 00:00 - 23:59 17 /06 00:00 - 23:59 31 /08 00:00 - 23:59 12 /11 00:00 - 23:59",
        "calendrierIntellectuel": "04:20 - 04:39"
    },
    {
        "numero": "15",
        "nom": "Hariel",
        "qualites": "Purification ; Pureté des moeurs, innocence ; Sentiments spirituels ; Découverte de nouvelles méthodes, inventions utiles ; Inspiration pour les scientifiques et les artistes ; Blanchit la conscience en lui infusant simultanément la Loi et la Connaissance ; Procure une grande lucidité, éveille le discernement ; Rétablit la communication entre l’individualité et la personnalité ; Libère de la paralysie, de ce qui empêche d’agir ; Libère de toutes les formes de dépendance",
        "distorsions": "Puritanisme - Perfectionnisme excessif, trop focalisé sur les détails, manque de vision globale - Caractère compliqué - Se rend complice des forces de l’abîme - Prêt à mourir pour imposer ou défendre une vérité non naturelle - Terrorisme, extrémisme - Esprit sectaire - Échec, effondrement - Lutte contre l’ordre naturel - Mentalité desséchante, esprit excessivement analytique, tendance à disséquer exagérément - Discernement faussé, jugement erroné, principes inversés - Séparatisme",
        "situations": "Sentiment d'être abandonné ; Altruisme ; Enfant ; Détachement ; Féminité ; Pardon ; Bonté ; Malveillance ; Miséricorde ; Pardon ; Possessivité ; Rancœur, sentiment de vengeance ; Réconciliation",
        "calendrierPhysique": "01/06 - 05/06",
        "calendrierEmotionnel": "23 /01 00:00 - 23:5904 /04 00:00 - 23:59 18 /06 00:00 - 23:59 01 /09 00:00 - 23:59 13 /11 00:00 - 23:59",
        "calendrierIntellectuel": "04:40 - 04:59"
    },
    {
        "numero": "16",
        "nom": "Hekamiah",
        "qualites": "Loyauté aux Principes Divins ; Attitude royale ; Respect des engagements ; Coordonnateur, pacificateur ; Franchise, noblesse ; Obtient des responsabilités ; Libérateur ; Amour Universel ; Devient un leader, un chef, un président ; Organisation politique et sociale",
        "distorsions": "Traîtrise, trahison, guerre, révolte - Mode de vie marqué par l’arrogance, le snobisme - Complexes de supériorité et d’infériorité - Envie, jalousie - Matérialisme excessif - Écartèlement, déchirement - Fait obstacle aux réalisations de notre nature supérieure - Égoïsme, amour trop personnel, passion - Complot, manigance - Provoque la dissidence dans le groupe, discorde, désaccord - Sentiment d’être diminué, servilité - Irresponsable - Idolâtre, égocentrique, mégalomane",
        "situations": "Adultère ; Ambassadeur ; Trahison ; Franchise ; Jalousie ; Loyauté ",
        "calendrierPhysique": "06/06 - 10/06",
        "calendrierEmotionnel": "24 /01 00:00 - 18:00 05 /04 00:00 - 23:59 19 /06 00:00 - 23:59 02 /09 00:00 - 23:59 14 /11 00:00 - 23:59",
        "calendrierIntellectuel": "05:00 - 05:19"
    },
    {
        "numero": "17",
        "nom": "Lauviah II",
        "qualites": "Révélations ; Faculté de compréhension intuitive, sans analyse et sans étude, télépathie, connaît les mécanismes de la psyché ; Agit contre les tourments et la tristesse ; État permanent de joie, ascension spirituelle ; Don pour la musique, la poésie, la littérature et la philosophie transcendantes, transcendantales ; Hautes Sciences ; Fait percevoir les grands mystères de l’Univers et les Lois Cosmiques pendant la nuit, révélations reçues en rêve, en songe et en méditation ; Pénètre l'inconscient",
        "distorsions": "Vit dans l’illusion, n’est pas assez ancré dans la réalité concrète - Ignorance, fausses perceptions, comportement erroné, athéisme, ne tient pas ses promesses - Tourments, dépression, tristesse - Insomnie, hyperactivité - Angoisse existentielle, anxiété, décrochage, marginalité choisie ou imposée - Prophète de malheur, esprit malsain et trompeur - Attitude hautaine, arrogante - Entêtement, mauvaise perception, problèmes matériels - Manque de foi, d’enthousiasme et de confiance en soi et envers les autres - Décalage entre le corps et l’esprit, se perd dans l’abstrait - Science sans conscience - Difficulté à exprimer le Savoir ",
        "situations": "Angoisse ; Désordres sociaux ; Rêves, interprétations des rêves ; Hallucinations ; Insomnie ; Genoux ; Pessimisme ; Tristesse ; Dormir ; Inquiétude ",
        "calendrierPhysique": "11/06 - 15/06",
        "calendrierEmotionnel": "24 /01 18:00 - 23:59 06 /04 00:00 - 23:59 20 /06 00:00 - 23:59 03 /09 00:00 - 23:59 15 /11 00:00 - 00:59",
        "calendrierIntellectuel": "05:20 - 05:39"
    },
    {
        "numero": "18",
        "nom": "Caliel",
        "qualites": "Vérité absolue ; Élimine tout doute, innocente ; Justice Divine, vision karmique ; Tribunal de conscience ; Discerne ce qui est juste ; Compréhension de l’interaction entre le bien et le mal ; Respect des Lois Divines ; Jugement parfait, honnêteté ; Juge, magistrat, avocat, notaire, ; Intégrité, amour de la Justice ; Intégrité, amour de la justice ; Aide à découvrir la Vérité, à retrouver la source d’élévation ; Capacité à deviner les intentions ",
        "distorsions": "Problèmes avec la vérité et la justice - Condamnation - Utilise la justice uniquement pour s’enrichir matériellement - Cherche à gagner, rivalise - Faux témoin, fausse preuve, flatterie - Procès injuste, adversité - Scandale, bassesse, corruption, malhonnêteté, fausseté - Situation confuse et embrouillée - S’éloigne de la Vérité, période ténébreuse ",
        "situations": "Accusations ; Franchise ; Honnêteté ; Juge, magistrat ; juré, jury ; Justice ; Lois, respect des ; Vérité ",
        "calendrierPhysique": "16/06 - 21/06",
        "calendrierEmotionnel": "25 /01 00:00 - 23:59 07 /04 00:00 - 23:59 21 /06 00:00 - 23:59 04 /09 00:00 - 23:59 16 /11 00:00 - 23:59",
        "calendrierIntellectuel": "05:40 - 05:59"
    },
    {
        "numero": "19",
        "nom": "Leuviah",
        "qualites": "Intelligence expansive ; Mémoire des vies antérieures, Mémoire Cosmique ; Capacité de mémorisation prodigieuse ; Porte de la Mémoire, Gardien des Archives de Daath (Bibliothèque Universelle) ; Maîtrise des sentiments par la raison, grande patience ; État d’âme communicatif, modestie, mentalité généreuse ; Permet de supporter l’adversité avec patience et acceptation ; Disponibilité à aider ceux qui en ont besoin ",
        "distorsions": "Problèmes d’intelligence, perte des facultés intellectuelles - Perfectionniste à outrance, n’est intéressé que par le gain matériel - Souvenirs inutiles, amnésie, trous de mémoire - Atrocités commises dans des vies passées - Chagrin, mortification, stérilité, esprit borné, méfiance - Tristesse, morosité, désespoir, attitude plaintive - Fait subir des pertes, induit l’amertume, personne compliquée - Accuse et culpabilise les autres - Manipule en utilisant les désirs, tente d’impressionner - Absence de chaleur humaine, incapacité à exprimer des sentiments - Met l’intelligence au service des forces obscures ",
        "situations": "Amnésie ; Dos ; Débuter, entreprendre, démarrer, capacité de commencer ; Oubli, oublier ; Lombaires ; Mémoire ; Mémoire, Les défaillances de la ; Réincarnation ; Vertèbres ",
        "calendrierPhysique": "22/06 - 26/06",
        "calendrierEmotionnel": "26 /01 00:00 - 23:59 08 /04 00:00 - 23:59 22 /06 00:00 - 23:59 05 /09 00:00 - 23:59 17 /11 00:00 - 23:59",
        "calendrierIntellectuel": "06:00 - 06:19"
    },
    {
        "numero": "20",
        "nom": "Pahaliah",
        "qualites": "Délivrance ; Transcendance de la sexualité, pureté de l’intimité, fusion sexuelle divine dans le couple, fidélité ; Éveil de la kundalini, de l’énergie vitale ; Sujets concernant la spiritualité et la morale ; Connaissance du bien et du mal ; Pureté, consent à des sacrifices pour évoluer ; Rectification des erreurs commises par des désirs exaltés ; Établit des règles dans le comportement instinctuel, rigueur ; Aide à traverser les épreuves avec courage et dynamisme ; Comportement moral irréprochable, grand initié ; Rédemption, rencontre avec le Moi Supérieur ; Vie spirituelle harmonieuse",
        "distorsions": "Abus de pouvoir, fanatisme, violence extrême - Lutte acharnée, destin difficile, rigidité - Problèmes avec la sexualité et/ou rejet de la vie sexuelle - Infidélité, libertinage, liaisons passagères, débauche - Abus et gaspillage sexuels, prostitution - Abattement, découragement, craintes, maladie - Ne croit pas en une Puissance Supérieure, transgresse les Lois Divines - Recherche de possessions matérielles - Religieux à la lettre, cherche à convertir",
        "situations": "Fanatisme ; Imposer ; Lois, respect des ; Lombaires ; Luxure ; Ordre (en général) ; Sexualité ",
        "calendrierPhysique": "27/06 - 01/07",
        "calendrierEmotionnel": "27 /01 00:00 - 23:59 09 /04 00:00 - 23:59 23 /06 00:00 - 23:59 06 /09 00:00 - 23:59 18 /11 00:00 - 23:59",
        "calendrierIntellectuel": "06:20 - 06:39"
    },
    {
        "numero": "21",
        "nom": "Nelkhael",
        "qualites": "Facilite l’apprentissage ; Aime les études, réussit les examens ; Omniscience ; Faculté d’aller du concret à l’abstrait, de la réalité à l’idée ; Don pour les sciences, la technologie et la poésie ; Compréhension de la géométrie, de l’astronomie, de l’astrologie et des mathématiques ; Inspire les savants et les philosophes ; Conscience de l’Organisation Cosmique ; Bonne concentration, faculté de comprendre les bienfaits de la récitation de mantras pour accéder à la Connaissance, recevoir des réponses et entrer en contact avec les mondes parallèles ; Anticipation ; Protège contre les calomnies, les pièges et les sortilèges ; Exorcisme par la Connaissance ; Enseignant, pédagogue par excellence ",
        "distorsions": "Difficultés d’apprentissage - Problèmes avec les examens, tendance à stresser - Veut plaire aux autres - Recherche le succès à tout prix, études motivées par l’ambition - Attitude hautaine - Complexes de supériorité et d’infériorité - Ignorance, apprend sans comprendre - Recherche et utilise la Connaissance à des fins personnelles - Rejette l’apprentissage, mentalité faible, se perd dans l’abstrait - Préjugés, comportement vindicatif - Constructions mentales erronées - Incapable d’appliquer la Connaissance - Envoûtement par manque de Connaissance ",
        "situations": "Calomnie ; Concentration, capacité de se concentrer ; Éducation ; Examens, études ; Ignorance ; Instruction ; Langues, apprentissage des langues ; Omniscience ; Enseignant, enseigner",
        "calendrierPhysique": "02/07 - 06/07",
        "calendrierEmotionnel": "28 /01 00:00 - 23:5910 /04 00:00 - 23:59 24 /06 00:00 - 23:5907 /09 00:00 - 23:59 19 /11 00:00 - 23:59",
        "calendrierIntellectuel": "06:40 - 06:59"
    },
    {
        "numero": "22",
        "nom": "Yeiayel",
        "qualites": "Renommée, célébrité ; Mécénat, philanthropie ; Activités politiques, artistiques et scientifiques ; Grande générosité ; Encourage la bonté ; Leader ; Commandement, leadership, diplomatie ; Fortune, prospérité, affaires, commerce, altruisme ; Permet de faire des découvertes surprenantes ; Voyages",
        "distorsions": "Mégalomane, tyran, profiteur - Esclavage, répression - Manipulation, acharnement, compétition - Non reconnu, désireux d’être riche et célè¬bre - Se sent non reconnu, désire être riche et célèbre - Difficulté à se reconnaître soi-même - Avide, insatiable, orgueilleux - Perte de reconnaissance - Sentiments contradictoires - Vie déséquilibrée, immobilisme, résistance à changer, à s’améliorer, à progresser - Difficultés dans le domaine des affaires, des entreprises, du commerce",
        "situations": "Célébrité, renommée ; Générosité ; Esclavage ; Voyages, protection lors des voyages ",
        "calendrierPhysique": "07/07 - 11/07",
        "calendrierEmotionnel": "29 /01 00:00 - 23:59 11 /04 00:00 - 23:59 25 /06 00:00 - 23:59 08 /09 00:00 - 23:59 20 /11 00:00 - 23:59",
        "calendrierIntellectuel": "07:00 - 07:19"
    },
    {
        "numero": "23",
        "nom": "Melahel",
        "qualites": "Capacité de guérir ; Médecin, guérisseur, pharmacologue, scientifique ; Naturopathie, herboristerie, sciences naturelles ; Connaît les propriétés des plantes médicinales ; Faculté d’agir soi-même comme une plante médicinale ; Nourriture et culture saines ; Pacifiste et apaisant ; Aptitude à comprendre que les aliments sains et bien combinés sont de véritables remèdes ; Connaissance de tous les cycles et étapes de la chaîne alimentaire ; Appréciation et gratitude pour l’Abondance Divine, que l’on ne prend pas pour acquise ; Pacifiste et apaisant ; Maîtrise des émotions, faculté de s’adapter à toute situation ; Foi qui anticipe la Connaissance ; Protection de l’environnement, respect de la nature ; Initié aux secrets des forces de la nature ; Capacité de comprendre les bienfaits multidimensionnels d’une nutrition végétarienne ou végétalienne saine et bien équilibrée",
        "distorsions": "Maladie, malaise, mal-être - Médecine sans conscience - Utilise la médecine uniquement pour s’enrichir matériellement - Pollution nuisible à la végétation et à l’environnement - Sentiments et entreprises corrompus - Difficulté à exprimer ce que l’on ressent et à improviser - Agriculture et nourriture artificielles - Esprit polluant et destructeur, pensées malsaines",
        "situations": "Brûlures / Crampes ; Fièvre ; Jardinage ; Homéopathie ; Maladie incurable / Infection, inflammation ; Intoxication ; Nutrition ; Parasite ; Plantes ; Pollution ; Peau / Varices ; Blessures, la guérison des blessures",
        "calendrierPhysique": "12/07 - 16/07",
        "calendrierEmotionnel": "30 /01 00:00 - 23:5912 /04 00:00 - 23:5926 /06 00:00 - 23:5909 /09 00:00 - 23:5921 /11 00:00 - 23:59",
        "calendrierIntellectuel": "07:20 - 07:39"
    },
    {
        "numero": "24",
        "nom": "Haheuiah",
        "qualites": "Protection ; Police, armée, avocat, juge ; Avertissement en cas de danger ; Honnêteté, incorruptibilité ; Bloque le mal, rend justice ; Protège les exilés et les immigrés ; Protège contre les voleurs et les assassins ; Protège contre les forces démoniaques ; Protège contre les animaux nuisibles ; Protège contre les sortilèges et les maléfices ; Aide à retourner au Pays d’Origine ; Sincérité, aime la Vérité ; Fin d’une période difficile ; Bonne intuition, capacité d’anticiper, de savoir à l’avance ce qui se passera ; Aide à accepter un jugement et à comprendre qu’il est en accord avec la Justice Divine ; Active le souhait de réparer les karmas qu’on a générés",
        "distorsions": "Problèmes relatifs à la protection - Abus de pouvoir, police, armée et/ou système juridique affectés par la corruption - Difficulté à obtenir justice ou à l’appliquer - Incompréhension du sens de l’épreuve - Instabilité, incohérence, égarement - Sentiment de vengeance, persécution, punition - Fuite devant les responsabilités - Indifférence, froideur émotionnelle - Forces démoniaques - Vit de moyens illicites, pose des gestes illégaux - Délinquant, criminel, récolte les fruits de la violence - Fraude, vol, emprisonnement - Victime de la rigidité judiciair",
        "situations": "Cambriolage ; Délinquance ; Protection (en général) ",
        "calendrierPhysique": "17/07 - 22/07",
        "calendrierEmotionnel": "31 /01 00:00 - 23:59 13 /04 00:00 - 23:59 27 /06 00:00 - 23:59 10 /09 00:00 - 23:59 22 /11 00:00 - 23:59",
        "calendrierIntellectuel": "07:40 - 07:59"
    },
    {
        "numero": "25",
        "nom": "Nith-Haiah",
        "qualites": "Porteur de la Sagesse et de l’Amour Suprêmes ; Maîtrise des forces spirituelles ; Étude de la métaphysique et de la Kabbale ; Compréhension de la notion du temps ; Entend la musique des Hautes Sphères ; Semblable aux Anges ; Peut tout obtenir ; Découverte des mystères cachés de la Création ; Révélations en songe et en rêve, facilite les visions ; Aide à trouver un lieu pour méditer ; Aime la paix, la solitude et le silence, personne calme ; Magie blanche, souhait du bien-être d’autrui ; Charisme spirituel ",
        "distorsions": "Amour et sagesse illusoires - Magie noire, pacte satanique - Prêt à tout pour atteindre son but - Faux pouvoirs spirituels - Manipulateur spirituel qui agit avec un complexe de supériorité - Incapacité à percevoir la magie de la vie et à accéder à ses multiples dimensions - Renonce à Dieu, au concept d’un Créateur Universel, athéisme - Possession, ensorcellement - Malheur, désespoir - Intérêt matériel, égocentrisme - Agité, incohérent, impatient - Va à l’encontre du Destin et des Lois Universelles",
        "situations": "Illusions ; Magie, magie noire ; Sagesse ",
        "calendrierPhysique": "23/07 - 27/07",
        "calendrierEmotionnel": "01 /02 00:00 - 23:59 14 /04 00:00 - 23:59 28 /06 00:00 - 23:59 11 /09 00:00 - 23:59 23 /11 00:00 - 23:59",
        "calendrierIntellectuel": "08:00 - 08:19"
    },
    {
        "numero": "26",
        "nom": "Haaiah",
        "qualites": "Discrétion ; Capacité de bien structurer le pouvoir et l’abondance ; Conseiller guidé par la Sagesse ; Faculté de garder et gérer les secrets d’État, les dossiers confidentiels des gouvernements, l’accès aux informations et connaissances qui doivent rester occultées ; Sens de l’organisation et de la famille ; Contemplation des Structures Divines ; Sciences politiques, harmonise la vie sociale ; Cohabitation pacifique ; Respect de l’Ordre Divin ; Capacité de s’adapter à toute situation ; Attitudes scientifiques et politiques en accord avec la Science Divine ; Leader politique et social, catalyseur, administrateur, décideur, diplomate, ambassadeur, justicier ; Permet de savoir comment se comporter lors de situations ambiguës ; Cherche la Vérité par le biais de la raison ; Créateur d’ambiances positives, constructives, moteur de l’esprit d’équipe ",
        "distorsions": "Indiscrétion, égocentrisme, problèmes familiaux et/ou sociaux - Difficultés en lien avec la politique, la prise de décision, l’administration, l’organisation - Motivé par l’ambition et la convoitise, jalousie, orgueil, vanité, passion - Fuit ses responsabilités, son plan de vie - Désir de pouvoir et de gloire terrestres, abus d’autorité et de pouvoir, esprit de compétition, loi de la jungle - Désordre social, anarchie, conspiration, traîtrise - Impose son point de vue, n’écoute pas les autres - Complexes d’infériorité et de supériorité - Conséquences négatives d’actions désordonnées ",
        "situations": "Ambassadeur ; Indiscrétion ; Renforcement des liens ; Négociation ; Politique ",
        "calendrierPhysique": "28/07 - 01/08",
        "calendrierEmotionnel": "02 /02 00:00 - 23:59 15 /04 00:00 - 23:59 29 /06 00:00 - 23:59 12 /09 00:00 - 23:59 24 /11 00:00 - 23:59",
        "calendrierIntellectuel": "08:20 - 08:39"
    },
    {
        "numero": "27",
        "nom": "Yerathel",
        "qualites": "Confiance ; Source d’Énergie inépuisable ; Propagation de la Lumière ; Créateur d’ambiance, optimisme ; Enseignement par la parole et l’écriture, diffusion sociale ; Civilise, sociabilise ; Libère des calomniateurs et des intentions malveillantes ; Libère en cas de possession ; Aime la justice, les sciences, la littérature et les arts en général ; Libère de ceux qui s’opposent à notre développement ; Disperse la confusion, conduit à la réussite ",
        "distorsions": "Manque de confiance en soi et/ou d’estime de soi ; ou surconfiance et problèmes d’égo - Activités superficielles - Hyperactivité, manque de concentration, de focalisation et de sagesse - Veut plaire à tout le monde - Est prêt à tout pour avoir du succès, être reconnu et apprécié - Dispersion, surexcitation - Possession, esclavage - Gaspillage - Dépendances, habitudes perverses, fanatisme - Désir compulsif de plaire, provocation - Joueur compulsif - Égoïsme, flatterie, emphase mise sur le paraître - Loi de la jungle, méchanceté, ignorance, intolérance, calomnie - Sciences et arts destructifs",
        "situations": "Confiance, manque de confiance ; Exorcisme ; Pieds ; Jambes ; Liberté ; Lumière ",
        "calendrierPhysique": "02/08 - 06/08",
        "calendrierEmotionnel": "03 /02 00:00 - 23:59 16 /04 00:00 - 23:59 17 /04 00:00 - 12:00 30 /06 00:00 - 23:59 13 /09 00:00 - 23:59 25 /11 00:00 - 23:59",
        "calendrierIntellectuel": "08:40 - 08:59"
    },
    {
        "numero": "28",
        "nom": "Seheiah",
        "qualites": "Prévoyance ; Longévité heureuse ; Protection contre la foudre, les chutes, les accidents, les incendies et les maladies ; Guérison miraculeuse, réhabilitation, santé ; Protection providentielle, Assurance Céleste ; Accorde la Sagesse via l’examen des expériences vécues ; Pressentiment, inspiration protectrice ; Prudence, capacité de prévoir les événements ; Grand calme ",
        "distorsions": "Imprévoyance, imprudence, inquiétude profonde - Problèmes de longévité, peur du changement et de la mort - Anxiété, peur du futur - Activités superficielles qui servent de compensation - Est toujours inquiet et préoccupé pour les autres, manque de confiance dans le Destin et les plans de vie prévus par l’Intelligence Cosmique pour tout un chacun - Incohérence - Chute, accident, maladie - Ruine, tumulte, turbulence - Déclenche des catastrophes - Action irréfléchie, écervelée, étourderie - Énergie tourbillonnante - Volonté excessive, caractère colérique - Paralysie intérieure et extérieure ",
        "situations": "Accidents ; Os ; Digestion ; Épuisement ; Hanches ; Foudre ; Longévité ; Paralysie ; Prudence ; Sciatique",
        "calendrierPhysique": "07/08 - 12/08",
        "calendrierEmotionnel": "04 /02 00:00 - 23:59 17 /04 12:00 - 23:59 18 /04 00:00 - 23:59 01 /07 00:00 - 23:59 14 /09 00:00 - 23:59 26 /11 00:00 - 23:59",
        "calendrierIntellectuel": "09:00 - 09:19"
    },
    {
        "numero": "29",
        "nom": "Reiyel",
        "qualites": "Libération ; Aime les grands espaces, les hautes montagnes et la nature en général ; Conduit vers les Hauts Sommets ; Libère du mal, des sortilèges et des ensorcellements ; Non attaché au credo (n’appartient à aucun groupe religieux ni à aucune secte) ; Amélioration de la vie par la méditation et l’étude de soi ; Confiance, diffusion de la Vérité ; Citoyen libre de l’Univers, vision globale ; Science du comportement ; Recherche de la Vérité, détachement matériel ; Conception, réalisation, production ; Découvre les mystères de l’Œuvre Divine par la méditation ; Travail inspiré par le Divin et réalisé avec une conscience supérieure ; Établit un lien avec les Guides spirituels ",
        "distorsions": "Situation limitative, impasse, manque de liberté à différents niveaux - Veut à tout prix être le premier et/ou le meilleur - Ambition, cupidité, manipulation - Est trop aérien ou trop terre-à-terre - Méfiance, fanatisme, hypocrisie - Propagation d’idées fausses et dangereuses - Ensorcellement, mauvaises fréquentations - Sectarisme, bigoterie, lutte religieuse - Endoctrinement, nationalisme - Prisonnier - Opposition aux réalisations altruistes - Philosophie matérialiste, plaisirs mondains ",
        "situations": "Confiance, manque de confiance ; Fanatisme ; Libération ; Liberté ; Amour Universelle, Amour Suprême ",
        "calendrierPhysique": "13/08 - 17/08",
        "calendrierEmotionnel": "05 /02 00:00 - 23:59 19 /04 00:00 - 23:59 02 /07 00:00 - 23:59 15 /09 00:00 - 23:59 27 /11 00:00 - 23:59",
        "calendrierIntellectuel": "09:20 - 09:39"
    },
    {
        "numero": "30",
        "nom": "Omael",
        "qualites": "Multiplication ; Matérialisation, développement, expansion ; Production, réalisation, application, planification ; Patience, sens des responsabilités ; Rétablit la santé, amène la guérison, touche le corps médical ; Fécondité, naissance, touche les femmes enceintes ; Épanouissement, joie, antidépresseur vivant ; Reconstituant et tonifiant ; Patron des règnes végétal et animal ; Favorise la plantation et les récoltes ; Redécouverte de l’enfant intérieur ",
        "distorsions": "Succès superficiel, philosophie matérialiste - Ambition, avidité, cupidité, est prêt à tout pour gagner, attitude mondaine - Stérilité, insuccès, échec répétitif, pauvreté - Matérialisation corrompue, manque de planification et d’organisation - Impatience - Vivisection (dissection sur le vivant) - Euthanasie, suicide, porteur de mort - Génocide, extermination, expériences monstrueuses, fureur dévastatrice - Tristesse, dépression, désespoir - Mauvaises récoltes ",
        "situations": "Abondance ; Acné / Herpès / Kystes / Peau; Agriculture ; Blasphème ; Cancer ; Enfant ; Culture ; Dépression ; Fertilité, Fécondité ; Jardinage ; Gynécologie ; Matérialisation ; Multiplication ; Plantes ; Grossesse; Utérus ",
        "calendrierPhysique": "18/08 - 22/08",
        "calendrierEmotionnel": "06 /02 00:00 - 23:5920 /04 00:00 - 23:5903 /07 00:00 - 23:5916 /09 00:00 - 23:5928 /11 00:00 - 23:59",
        "calendrierIntellectuel": "09:40 - 09:59"
    },
    {
        "numero": "31",
        "nom": "Lecabel",
        "qualites": "Talent pour résoudre les énigmes de la Vie ; Amour de l’exactitude et de la précision ; Excellence, recherche de l’ordre à tous les niveaux ; Lucidité, intellect puissant, trouve des solutions pratiques ; Maîtrise des émotions par la raison ; Stratège, gestionnaire, ingénieur, architecte, agronome ; Décideur, créateur, concepteur, planificateur de l’avenir ; Étude des sciences exactes, direction d’entreprise ; Idées lumineuses et génératrices d’abondance ; Révélation des Processus Cosmiques par l’observation de l’infiniment petit ; Respect des étapes et des cycles, planification à long terme ",
        "distorsions": "Manque de talent, d’inspiration, d’idées, et/ou frustration résultant du fait qu’on n’arrive pas à utiliser, à déployer pleinement ses propres talents - Manipule et exploite les autres - Insécurités qui poussent la personne à être obsédée par le succès - Moyens illicites, affaires louches, trafic de drogue - Opportuniste, malhonnête, avare - Gère de manière trop analytique, perfectionniste insatisfait - Laisser-aller, négligence, je-m’en-foutisme, gaspillage - Mauvaise utilisation du capital et des ressources, pertes en affaires, faillite, problèmes insolubles, agit trop hâtivement - Possessivité, s’attache aux résultats, essaie de forcer le Destin - Joueur compulsif ",
        "situations": "Agriculture ; Efficacité ; Excellence ; Intelligence ; Inventions ; Organisation ; Précision ; Professions ; Talents ; Métiers, Professions ",
        "calendrierPhysique": "23/08 - 28/08",
        "calendrierEmotionnel": "07 /02 00:00 - 23:5921 /04 00:00 - 23:5904 /07 00:00 - 23:5905 /07 00:00 - 12:0017 /09 00:00 - 23:5929 /11 00:00 - 23:59",
        "calendrierIntellectuel": "10:00 - 10:19"
    },
    {
        "numero": "32",
        "nom": "Vasariah",
        "qualites": "Clémence / Capacité de pardonner ; Grande sagesse qui aide à réfléchir, planifier, trouver des solutions, résoudre des problèmes ; Planificateur, penseur, stratège ; Bonté, bienveillance, magnanimité ; Modestie, amabilité ; Compréhension du sens de l’épreuve ; Aide à se libérer du sentiment de culpabilité ; Noblesse, droiture, sens élevé de la justice ; Pardon naturel ; Juge, magistrat, avocat ; Don oratoire ; Confère l’accès à la Mémoire Cosmique, Connaissance du bien et du mal ; Mentalité généreuse ",
        "distorsions": "Manque de clémence, difficulté à pardonner - Sagesse déficiente, manque de bonté, difficulté ou incapacité à planifier, réfléchir, trouver des solutions, résoudre des problèmes - Vengeance - Injuste, ignoble, rancunier - Culpabilité, accusation, condamnation - Fuite face aux responsabilités, difficulté à discerner le bien du mal - Résiste à évoluer, nourrit des intentions nuisibles aux autres - Puritain, moraliste, influence néfaste - Maladie pouvant s’aggraver - Focalise sur les mauvais souvenirs - Présomptueux, impoli, mal élevé - Orgueilleux, matérialiste ",
        "situations": "Accusations ; Élocution, problèmes d'élocution ; Oubli / Culpabilité, sentiment de culpabilité ; Honnêteté ; Juge, magistrat ; juré, jury ; Justice ; Lois, respect des ; Litige ; Magnanimité ; Mémoire ",
        "calendrierPhysique": "29/08 - 02/09",
        "calendrierEmotionnel": "08 /02 00:00 - 23:5922 /04 00:00 - 23:5905 /07 12:00 - 23:5906 /07 00:00 - 23:5918 sept 00:00 - 23:5930 nov 00:00 - 23:59",
        "calendrierIntellectuel": "10:20 - 10:39"
    },
    {
        "numero": "33",
        "nom": "Yehuiah",
        "qualites": "Subordination ; Autorité juste et constructive, leadership de haut niveau ; Bonne dynamique de travail avec ses supérieurs, capacité de créer un esprit d’équipe et de travailler en collaboration étroite avec des personnes importantes ; Faculté de comprendre globalement la structure d’une entreprise, d’une mission, d’une réalité, etc., de planifier des projets importants, d’assumer de grandes responsabilités ; Appréciation, reconnaissance et confiance de la part des supérieurs ; Inspire la confiance et la loyauté ; Aptitude à supporter de hautes tensions, des initiations puissantes ; Aide à lâcher prise, écarte les confrontations ; Fidélité à ce qui est supérieur, honnêteté ; Capacité de reconnaître la véritable Hiérarchie ; Conscience de sa place dans l’Ordre Cosmique ; Permet de démasquer les traîtres et de découvrir les machinations ; Soutient les initiatives altruistes, induit le sens du devoir ; Donne lieu à des découvertes scientifiques ; Personne de confiance ; Engagement, contrat, alliance, association philanthropique ",
        "distorsions": "Insubordination, problèmes avec l’autorité et la hiérarchie - Rébellion, confrontation, résistance ou refus d’exécuter les ordres, agressivité - Ne supporte pas la hiérarchie, écarté ou retiré des postes de commande - Confronte les ordres d’En Haut - Perversité, désirs multiples, manque de fermeté et de force morales pour faire ce qui est juste - Marginalité, quête de l’inutile, abandon - Conflit, trahison inscrite dans le code génétique, dans l’inconscient - Mépris - Sentiments de supériorité et d’infériorité - Plaisirs mondains, tendance à abuser des privilèges - Manque de loyauté, trahison - Problèmes d’égo - Impose sa volonté et sa présence, comportement dictateur - Rigidité, colère ",
        "situations": "Agression ; Alcoolisme ; Imposer ; Jambes ; Rebelle ",
        "calendrierPhysique": "03/09 - 07/09",
        "calendrierEmotionnel": "09 /02 00:00 - 23:5923 /04 00:00 - 23:5907 /07 00:00 - 23:5919 /09 00:00 - 23:5901 /12 00:00 - 23:59",
        "calendrierIntellectuel": "10:40 - 10:59"
    },
    {
        "numero": "34",
        "nom": "Lehahiah",
        "qualites": "Obéissance ; Fidèle serviteur ; Confiance et faveur des supérieurs ; Discipline, sens de l’ordre ; Loyauté, dévouement, actions altruistes ; Soumis aux Lois Divines et à l’autorité qui les représente ; Consacre sa vie au service d’un ordre établi (chef de gouvernement, ministre, président, directeur) ; Intelligence, paix, harmonie, est à l’aise même dans l’ambiguïté ; Obéissance sans forcément comprendre ; Compréhension de la Justice Divine ; Incorruptible, intègre, responsable ; Accepte la rigueur de sa destinée sans protester",
        "distorsions": "Désobéissance - Problèmes avec l’autorité - Manque d’autorité, incompréhension - Complexes d’infériorité et de supériorité - Personne déloyale, non fiable, en qui on ne peut pas avoir confiance - Lois injustes, autoritarisme, dictature - Manque d’autorité, incompréhension - Esprit compétitif, opposition, contredit pour avoir raison - Rigidité, frustration, conflits avec ses supérieurs - Colère dangereuse, traîtrise, déclenche la ruine, la destruction - Discorde, rébellion, violence, guerre - Nature émotive, tendance à se révolter contre les lois - Rejet, impulsivité, absence de réceptivité, rigidité ",
        "situations": "Délinquance ; Obéissance ",
        "calendrierPhysique": "08/09 - 12/09",
        "calendrierEmotionnel": "10 /02 00:00 - 23:5924 /04 00:00 - 23:5908 /07 00:00 - 23:5920 /09 00:00 - 21 /09 00:5921 /09 00:00 - 12:0002 /12 00:00 - 23:59",
        "calendrierIntellectuel": "11:00 - 11:19"
    },
    {
        "numero": "35",
        "nom": "Chavakhiah",
        "qualites": "Réconciliation ; Relations familiales harmonieuses ; Confiance, aide et support familiaux ; Conscience du sens sacré des liens familiaux ; Capacité de faire émerger la Sagesse ancestrale ; Rapproche les êtres, renoue les liens ; Sciences humaines et sociales ; Aime la paix, médiateur, conciliateur ; Loyauté récompensée, services appréciés ; Héritage, partage des biens, dons ; Retour au paradis perdu",
        "distorsions": "Problèmes familiaux, disputes, querelles, désunion - Difficultés et discorde en lien avec l’héritage - Jalousie, envie, trahison - Attachement excessif, malsain, possessivité, essaie de contrôler les autres - Problèmes en lien avec des traditions familiales anciennes - Attachement au passé, maintien de coutumes et de schémas de fonctionnement ancestraux et familiaux dépassés et injustes - Mariage forcé - Veut à tout prix plaire à la famille, dépendance émotionnelle et matérielle - Absence de liens, égoïsme - Procès injustes - Offense - Ruine - Bigoterie, esprit borné, sectarisme, racisme, nationalisme - Maladies héréditaires - Problèmes humanitaires - Désorganisation et désordre sur le plan social",
        "situations": "Fidélité ; Héritage ; Renforcement des liens ; Parents ; Querelles ; Réconciliation ; Succession",
        "calendrierPhysique": "13/09 - 17/09",
        "calendrierEmotionnel": "11 /02 00:00 - 23:5925 /04 00:00 - 23:5909 /07 00:00 - 23:5921 /09 12:00 - 23:5922 /09 00:00 - 23:5903 /12 00:00 - 23:59",
        "calendrierIntellectuel": "11:20 - 11:39"
    },
    {
        "numero": "36",
        "nom": "Menadel",
        "qualites": "Travail ; Vocation, coopération, serviabilité, altruisme ; Contremaître de l’Usine Divine ; Aide à trouver un emploi ; Procure des moyens de subsistance ; Vérité et liberté trouvées dans le travail ; Travail intérieur, facilite l’adaptation ; Libère les prisonniers et les exilés ; Compréhension du travail ; Procure la volonté pour se mettre au travail ; Récupération de son propre potentiel ; Dévouement ",
        "distorsions": "Problèmes reliés au travail et aux activités professionnelles - Fait son travail pour être aimé - Excès ou manque de travail - Vit uniquement pour son travail, s’identifie principalement à son rôle et son statut social - Philosophie matérialiste - Esclavage - Perte d’emploi, difficulté à trouver un emploi - Exil, fuite, paresse, évite les responsabilités - Manque d’objectifs et d’intensité, rareté des idées - Personne trop affairée dans la matière - Épuisement, froideur, isolement - Incompréhension du sens profond du travail - Essaie de forcer, de contrecarrer ou de défier le Destin, cherche la réussite à tout prix - Recherche de gloire personnelle ",
        "situations": "Émigration ; Employeur ; Jambes ; Prisonniers ; Professions ; Esclavage ; Métiers, Professions ; Chômage ; Vocation ; Travail",
        "calendrierPhysique": "18/09 - 23/09",
        "calendrierEmotionnel": "12 /02 00:00 - 23:5926 /04 00:00 - 23:5910 /07 00:00 - 23:5923 /09 00:00 - 23:5904 /12 00:00 - 23:59",
        "calendrierIntellectuel": "11:40 - 11:59"
    },
    {
        "numero": "37",
        "nom": "Aniel",
        "qualites": "Brise les vieux schémas ; Aide à comprendre les cycles de la vie et de l’évolution ; Étude de l’histoire, des causes et des conséquences ; Compréhension de la Loi du karma, du fait qu’on attire ce qu’on est et qu’on récolte ce qu’on sème ; Changement de mentalité, nouvelles idées ; Développe une volonté d’indépendance ; Aide à purifier les mémoires négatives en lien avec la sexualité, la dépendance affective ainsi que toute autre forme de dépendance ; Maîtrise face aux impulsions intellectuelles et émotionnelles intenses ; Autonomie spirituelle ; Libère des forces et émotions négatives ; Porteur de nouvelles sciences et de nouvelles conceptions de l’Univers ; Encourage la nouveauté",
        "distorsions": "Difficulté, refus ou peur de changer - Tendance à vivre dans les mémoires du passé - Philosophe matérialiste, mentalité trop terre-à-terre - Incompréhension ou ignorance de la Loi du karma, du fait qu’on récolte ce qu’on sème - Résistance face aux nouveaux courants - Attachement aux structures anciennes, à ce qui est ancien - Assujettissement à la matière - Tourne en rond en ressassant les mêmes pensées - Lutte acharnée pour maintenir le statu quo - Charlatan, esprit pervers et trompeur - Traditionaliste farouche - Dépendances de toutes sortes - Parle de ce qu’il ne connaît pas ",
        "situations": "Changement (en général) ; Dépendances ; Détachement ",
        "calendrierPhysique": "24/09 - 28/09",
        "calendrierEmotionnel": "13/02 00:00 - 23:5927 /04 00:00 - 23:5911 /07 00:00 - 12 /07 23:5924 /09 00:00 - 25 /09 23:5905 /12 00:00 - 23:59",
        "calendrierIntellectuel": "12:00 - 12:19"
    },
    {
        "numero": "38",
        "nom": "Haamiah",
        "qualites": "Sens des rituels et des préparations ; Stratège, planificateur ; Aime faire des choses, préparer des repas, s’occuper d’autrui, etc. ; Conduit vers les plus hautes réalisations humaines ; Transpose le rituel dans le quotidien ; Science du comportement, de la conduite ; Beauté, harmonie, paix ; Savoir-vivre, politesse, convivialité ; Haut lieu de transcendance ; Exorcisme ; Dissout la violence intérieure et extérieure ; Aide à trouver le parfait complément ; Histoire d’amour extraordinaire ; Sexualité vécue divinement, dans une conscience sacrée ; Rituels, cérémonies, initiations ; Adore le Divin",
        "distorsions": "Manque d’implication ou difficultés en lien avec la préparation et les rituels - Facultés de stratège et de planificateur limitées, insuffisantes - N’aime pas faire des choses, préparer des repas, prendre soin des autres, etc. - Égoïsme, manque de considération pour les autres - Impatience - Manque de politesse, de gentillesse, de savoir-vivre - Trop perfectionniste, fait les choses par insécurité ou par peur de manquer de ressources - Suit des rituels ou s’y soumet pour plaire aux autres, pour être accepté et aimé - Refuse ou craint le mariage, absence ou manque d’amour véritable - Mensonge, erreur, refus de respecter les règles, comportement manipulateur - Absence de spiritualité ou concepts spirituels faux, non authentiques - Adorateur de monuments - Cultes, rituels et cérémonies de magie noire - Démon, esprit malveillant, possession, agression, violence - Guidé par des intérêts matériels",
        "situations": "Amabilité ; Exorcisme ; Magie, magie noire ; Préparation (sur tous les plans) ; Rituels, sens des ",
        "calendrierPhysique": "29/09 - 03/10",
        "calendrierEmotionnel": "14/02 00:00 - 23:5928 /04 00:00 - 23:5912 /07 00:00 - 23:5925 /09 00:00 - 23:5906 /12 00:00 - 23:59",
        "calendrierIntellectuel": "12:20 - 12:39"
    },
    {
        "numero": "39",
        "nom": "Rehael",
        "qualites": "Soumission, réceptivité ; Grande sensibilité ; Ouverture de conscience générant une compréhension profonde ; Respect de la Hiérarchie ; Capacité d’écouter les autres ; Confère la confiance des supérieurs ; Parfaite soumission à des parents et à une autorité justes ; Amour paternel ; Obéissance et respect ; Guérison des maladies mentales, des dépressions et de l’angoisse ; Régénération",
        "distorsions": "Problèmes avec la soumission, tendance à être trop soumis ou à refuser de se soumettre, insubordination, rébellion - Manque de réceptivité et d’ouverture - Hypersensibilité ou insensibilité - Difficulté à être à l’écoute des autres, est trop centré sur soi - Attitude mégalomane - Irrespect de la hiérarchie sur tous les plans - Crime contre les parents et les enfants - Projections des parents sur leurs enfants, de ce qu’ils n’ont pas réussi - Violence, haine, cruauté - Autoritarisme - Impose l’obéissance avec une sévérité cruelle - Maladies mentales - Problèmes émotionnels - Anxiété, angoisse, dépression, suicide",
        "situations": "Autoritarisme ; Ouïe ; Père ; Hernies ; Genoux ; Méningite ; Prostate",
        "calendrierPhysique": "04/10 - 08/10",
        "calendrierEmotionnel": "15/02 00:00 - 23:5929 /04 00:00 - 23:5913 /07 00:00 - 23:5926 /09 00:00 - 23:5907 /12 00:00 - 23:59",
        "calendrierIntellectuel": "12:40 - 12:59"
    },
    {
        "numero": "40",
        "nom": "Ieiazel",
        "qualites": "Consolation, réconfort ; Appréciation, phase de renouveau ; Consolation après les efforts ; Restaure et revitalise le corps, aide à récupérer la pleine forme ; Empêche les débordements émotionnels ; Aide à maîtriser la passion et les énergies très intenses ; Libère des conditionnements affectifs et des dépendances de toutes sortes (alcool, drogue, etc.) ; Délivre les prisonniers ; Fin d’une période d’épreuves ou de situations difficiles, marque une nouvelle période plus facile ; Apporte paix, harmonie et réjouissance ; Commencement d’une nouvelle création ; Touche l’écriture, les éditeurs, l’imprimerie, les libraires, les bibliothèques, la lecture, la musique, la peinture et les arts en général",
        "distorsions": "Difficulté à réconforter les autres - Manque d’intimité et de renouveau - Pensées pessimistes, tristesse - Accumulation de problèmes, épreuves, période difficile - Éclatements et débordements émotionnels - Dépendances, passion, sentiments tumultueux - Découragement, manque de confiance - Tendance à fuir la vie sociale, réclusion - Maladie pouvant entraîner la mort - Écrits malheureux, tristes, pessimistes - Musique et autres formes d’art destructrices",
        "situations": "Alcoolisme ; Librairie ; Consolation ; Dépendances ; Musique ; Chant, Chanter ; Écriture, écrivain ",
        "calendrierPhysique": "09/10 - 13/10",
        "calendrierEmotionnel": "16/02 00:00 - 23:5930 /04 00:00 - 23:5914 /07 00:00 - 23:5927 /09 00:00 - 23:5908 /12 00:00 - 23:59",
        "calendrierIntellectuel": "13:00 - 13:19"
    },
    {
        "numero": "41",
        "nom": "Hahahel",
        "qualites": "Mission ; Fidèle serviteur ; Donne inconditionnellement ; Berger des âmes, missionnaire ; Vocation en rapport avec la spiritualité ; Attise la foi ; Richesse spirituelle ; Non-attachement aux mondanités ; Agit dans l'invisible de manière impersonnelle et détachée ; Prévient des ennemis de la spiritualité ; Révèle le Créateur Universel ; Capacité de se sacrifier, grandeur d’âme ; Leadership, courage, capacité de faire de grands efforts pour aider et soutenir autrui ; Compréhension du sens et du but de la Vie ; Grand Sage ; Visionnaire, sait ce qui doit être fait et quand le faire ; Méditation active, capacité de méditer tout en étant en action ; Consacre sa vie au Divin ; Guide spirituel ; Faculté de marier l’esprit et la matière ",
        "distorsions": "Difficulté à trouver ou à reconnaître sa mission et à comprendre le sens et le but de l’existence - Philosophie matérialiste, égoïsme, manque d’altruisme - Se sent écarté, isolé, seul, nourrit des décalages à l’intérieur de soi, sentiment d’être séparé des autres - Rigidité, extrémisme spirituel - Fait mauvais usage de son autorité - Cherche à convaincre - S’identifie aux martyrs, se sent persécuté - Combat ce qu’il ne peut pas être - Comportement scandaleux - Échoue dans ses projets - Fausse vertu, basée sur l’apparence seulement - Renie sa divinité - Ennemi de la spiritualité - Moquerie, mépris, haine - Inquisition, extrémisme religieux",
        "situations": "Martyr, martyre ; Amour Universelle, Amour Suprême / Vocation ",
        "calendrierPhysique": "14/10 - 18/10 ",
        "calendrierEmotionnel": "17/02 00:00 - 23:5901 /05 00:00 - 23:5915 /07 00:00 - 23:5928 /09 00:00 - 23:5909 /12 00:00 - 23:59",
        "calendrierIntellectuel": "13:20 - 13:39"
    },
    {
        "numero": "42",
        "nom": "Mikael",
        "qualites": "Ordre politique ; Instaure sur la Terre les Lois du Ciel ; Sens de l’organisation sociale et spirituelle ; Structure le succès et l’expansion ; Planification sage et prévoyante ; Aide à comprendre l’adversité ; Connaissance du bien et du mal ; Procure la lucidité et la vision globale ; Démasque les traîtres ; Permet de découvrir les secrets, les mystères ; Autorité naturelle, obéissance, fidélité ; Président, chef, responsable, ministre, ambassadeur, consul ; Enseignant ; Sécurité et protection lors de voyages ; Protège contre les accidents ; Succès dans les relations extérieures ; Instruit et enseigne pendant la nuit ; Instaure le Pouvoir absolu de l’Esprit ",
        "distorsions": "Problèmes politiques et sociaux, désordre, difficultés sur le plan organisationnel - Abus de pouvoir ou incapacité à décider et à être un bon dirigeant - Difficulté à prévoir et à planifier avec sagesse, se perd dans les détails, dans les aspects techniques - Envie, jalousie, trahison - Mauvais enseignant ou leader, ne donne pas le bon exemple - Complexes de supériorité et d’infériorité - Système démocratique qui légalise l’expression des bas instincts - Double jeu, gouvernement corrompu - Profère des paroles qui ne correspondent pas à la Pensée Divine - Trahison des idéaux, propagateur de fausses nouvelles - Mensonge, calomnie, diffamation, conspiration, traîtrise - Accidents ",
        "situations": "Accidents ; Ambassadeur ; Éducation / Instruction ; Lois, respect des lois / Ordre (en général) ; Politique ; Enseignant, enseigner ; Voyages, protection lors des voyages ; Accidents ; Ambassadeur ; Éducation / Instruction ; Lois, respect des lois / Ordre (en général) ; Politique ; Enseignant, enseigner ; Voyages, protection lors des voyages ",
        "calendrierPhysique": "19/10 - 23/10",
        "calendrierEmotionnel": "18 /02 00:00 - 23:5902 /05 00:00 - 23:5916 /07 00:00 - 23:5929 /09 00:00 - 30 /09 23:5910 /12 00:00 - 23:59",
        "calendrierIntellectuel": "13:40 - 13:59"
    },
    {
        "numero": "43",
        "nom": "Veuliah",
        "qualites": "Prospérité ; Richesse, abondance, joie, enrichit la conscience ; Abondance de sentiments nobles ; Fin stratège pour vaincre les ennemis intérieurs et extérieurs ; Compréhension du fait que l’argent est une énergie que l’on peut employer à bon ou à mauvais escient ; Aptitude à utiliser la prospérité de manière responsable, juste et altruiste ; Commerce, affaires, finances (système bancaire, comptabilité, etc.), administration, management ; Fait tout fructifier, rend service aux autres ; Autorité naturelle, confiance de la part des supérieurs ; Ouverture de conscience qui libère des motivations obscures et des habitudes vicieuses et pernicieuses ; Paix, plénitude ; Prépare le futur patronat ; Donne inconditionnellement ; Visionnaire, faculté de prévoir et de planifier en avance ; Philanthrope ; Compréhension du fait qu’on récolte ce que l’on sème ",
        "distorsions": "Prospérité artificielle et illusoire, philosophie matérialiste - Gaspillage d’argent et d’énergie, recherche de paradis artificiels - Discorde, perte de privilèges, ruine, pauvreté - Insécurité profonde, inquiétude face à l’avenir - Avarice, vol, richesse recherchée et obtenue par des moyens illicites - Pense que l’argent peut tout acheter - Égoïsme, égotisme - Esprit limité, borné, aveuglé par le pouvoir personnel - Complexes de supériorité et d’infériorité - Abus de pouvoir, lutte existentielle - Division, séparatisme, révolution, guerre, destruction - Comportements et actes générateurs de karmas, de problèmes futurs - Endommagement et destruction de l’environnement - Manque d’amour et de sagesse - Mégalomanie ",
        "situations": "Abondance ; Luxure ; Paix ; Guerrier (de Lumière) ",
        "calendrierPhysique": "24/10 - 28/10",
        "calendrierEmotionnel": "19/02 00:00 - 23:5903 /05 00:00 - 23:5917 /07 00:00 - 23:5930 /09 00:00 - 23:5911 /12 00:00 - 23:59",
        "calendrierIntellectuel": "14:00 - 14:19"
    },
    {
        "numero": "44",
        "nom": "Yelahiah",
        "qualites": "Guerrier de Lumière, Armée Céleste ; Protecteur Universel ; Guide spirituel ; Application de la Justice Divine ; Capacité de résoudre les conflits créés par notre comportement ; Aide dans les initiations ; Talent militaire au service des justes causes ; Vie orientée vers la liquidation des dettes karmiques ; Fait remporter la victoire et installe la paix ; Franchise, loyauté, courage, bravoure ; Succès dans les entreprises ; Sagesse acquise ; Compréhension du fonctionnement des frontières dans les mondes parallèles ; Accorde le Passeport universel ; Aide à bénéficier de la confiance des supérieurs ; Obéissance à ce qui est juste ; Grande faculté d’aider les autres ",
        "distorsions": "Prétend être un guerrier de Lumière, extrémisme spirituel - Impose sa volonté, ses convictions, sa croyance, essaie de contrôler les autres - Mission diabolique, forces démoniaques - Abus de pouvoir, dictature, mégalomanie - Fanatisme, terrorisme - Guerre, fléau, comportement agressif, brutal, vindicatif - Massacre et traitement impitoyable de prisonniers - Infraction des lois, criminel, malfaiteur - Emprisonnement - Injustice - Tendance au surmenage ",
        "situations": "Agression ; Courage, bravoure ; Force de volonté ; Aide à résoudre le karma ; Guerrier (de Lumière) ",
        "calendrierPhysique": "29/10 - 02/11",
        "calendrierEmotionnel": "20/02 00:00 - 23:59 04/05 00:00 - 23:5918 /07 00:00 - 23:5901 /10 00:00 - 23:5912 /12 00:00 - 23:59",
        "calendrierIntellectuel": "14:20 - 14:39"
    },
    {
        "numero": "45",
        "nom": "Sealiah",
        "qualites": "Motivation, pureté des intentions ; Volonté retrouvée, concentration, focalisation ; Ardeur, enthousiasme, espoir ; Ressort, réveil, moteur de l'Univers qui réveille les endormis ; Redémarre ce qui est embourbé ; Redonne l'espoir aux humiliés et aux déchus ; Confond les orgueilleux et les vaniteux ; Exalte la conscience ; Retour à l'équilibre de la force vitale ; Porteur de santé et de guérison ; Patron des quatre éléments : feu, air, eau, terre ",
        "distorsions": "Manque ou excès de motivation et d’enthousiasme - Orgueilleux, vaniteux, excessif - Hyperactivité ou manque d’énergie et d’activité - Imbu, despote, difficile à vivre - Déséquilibre et déchaînement des éléments naturels (tremblements de terre, inondations, sécheresses, tornades, éruptions volcaniques, etc.) et de leurs correspondants sur le plan intérieur - Vie difficile, épreuves - Autocontrôle et confiance en soi excessifs ou insuffisants, manque de maîtrise - Exagération, tendance à vouloir forcer le Destin ",
        "situations": "Éveil ; Dos ; Confiance, manque de confiance ; Égocentrisme, égoïsme ; Enthousiasme, manque d'enthousiasme ; Coeur ; Ouragan ; Paresse ; Muscles ; Optimisme ; Stimulation ; Affaiblissement ; Volonté ",
        "calendrierPhysique": "03/11 - 07/11",
        "calendrierEmotionnel": "21/02 00:00 - 23:59 05/05 00:00 - 23:5919 /07 00:00 - 23:5902 /10 00:00 - 23:5913 /12 00:00 - 23:59",
        "calendrierIntellectuel": "14:40 - 14:59"
    },
    {
        "numero": "46",
        "nom": "Ariel",
        "qualites": "Perception révélatrice ; Facultés médiumniques, clairvoyance, clairsentience, clairaudience ; Découverte de trésors cachés ; Méditations, rêves et signes révélateurs ; Découverte des secrets de la nature ; Reconnaissance, gratitude ; Subtilité, discrétion ; Porteur d’idées nouvelles, inventeur ; Découverte de secrets philosophiques qui amènent à réorienter sa vie ",
        "distorsions": "Fausse perception ou difficulté à recevoir des révélations - Manque de spiritualité ou illusions spirituelles créées et nourries par l’égo - Médiumnité sans pureté - Problèmes résultant de perceptions fausses et/ou de facultés médiumniques utilisées avec de mauvaises intentions - Mentalité faible - Incohérence, indécision, comportement insensé, tribulations - Timidité maladive, paralysante - Difficulté ou incapacité à trouver des solutions - Activité inutile ",
        "situations": "Clairaudience, clairsentience, clairvoyance, télépathie ; Inventions ",
        "calendrierPhysique": "08/11 - 12/11",
        "calendrierEmotionnel": "22/02 00:00 - 23:59 06/05 00:00 - 23:5920 /07 00:00 - 23:5903 /10 00:00 - 23:5914 /12 00:00 - 23:59",
        "calendrierIntellectuel": "15:00 - 15:19"
    },
    {
        "numero": "47",
        "nom": "Asaliah",
        "qualites": "Contemplation ; Glorification du Divin, expérience mystique ; Perspective globale, vision d’ensemble ; Contemple d’un point de vue élevé ; Synthétise facilement l’information ; Initié, facultés supranormales ; Pédagogue, instructeur, enseignant ; Psychologue ; Trouve la Vérité dans les petites choses du quotidien ; Révélation des Processus Cosmiques ; Génie créateur, stratège, talent de planificateur ; Intuitif, équilibré, rayonne par le discernement et l’intégrité ; Grand intérêt pour l’ésotérisme ; Faculté d’accéder aux mondes parallèles, d’entrer en contact avec leurs habitants et/ou avec des personnes décédées ; Aptitude à atteindre de hauts niveaux spirituels par la méditation et la visualisation ; Développement du pouvoir mental et de la faculté de concentration et de focalisation grâce à la récitation de mantras ; Compréhension de l’importance de vivre la fusion des deux polarités et la sexualité avec une conscience spirituelle et le sens du sacré ; Plaisirs Divins, pureté de l’intention ; Haute moralité, valeurs authentiques et véritables, respect et fidélité au sein du couple ",
        "distorsions": "Manque de vision globale, ou tendance à se perdre dans des structures mentales et des concepts abstraits, déconnexion de la réalité concrète - Philosophie matérialiste, focalisation excessive sur les besoins primaires, problèmes d’égo - Insécurités, soucis, troubles intérieurs - Actions immorales et scandaleuses - Vérité inversée, malhonnêteté, charlatan, faux professeur - Fausses croyances, enseignement de systèmes erronés et dangereux, admiration aveugle, idolâtrie - Dissèque et analyse de façon exagérée - Mensonge, erreur d’appréciation, ignorance - S’attribue l’incarnation de personnages illustres - Abus et gaspillage sexuels ",
        "situations": "Éducation ; Perspicacité ; Enseignant, enseigner ",
        "calendrierPhysique": "13/11 - 17/11",
        "calendrierEmotionnel": "23/02 00:00 - 23:5907 /05 00:00 - 23:5921 /07 00:00 - 23:5904 /10 00:00 - 23:5915 /12 00:00 - 23:59",
        "calendrierIntellectuel": "15:20 - 15:39"
    },
    {
        "numero": "48",
        "nom": "Mihael",
        "qualites": "Fertilité, fécondité ; Harmonie et paix conjugales ; Mariage, fidélité conjugale ; Réconciliation, fusion des polarités masculine et féminine ; Reproduction, croissance ; Aide à engendrer une grande âme ; Sexualité vécue divinement ; Destin facile dans les associations et les partenariats ; Don de clairvoyance, amélioration de la perception ; Paix intérieure et extérieure ; Aide à matérialiser les Intentions Divines ; Protection providentielle ; Réceptivité et écoute appliquées avec sagesse ",
        "distorsions": "Stérilité, difficulté à engendrer un enfant ou à concevoir un projet - Discorde, désaccord entre les conjoints, jalousie, inconstance, infidélité - Crainte de perdre l’autre, possessivité, asservissement, machisme - Problèmes sexuels et/ou luxure, passion, recherche du plaisir des sens pour compenser l’absence de vie spirituelle - Entreprises infructueuses - Revendication de la place de l’autre, compétition - Sentiments d’attraction et de répulsion - Relations multiples, libertinage, prostitution ",
        "situations": "Abondance ; Divorce ; Fertilité, Fécondité ; Imposer ; Jalousie ; Possessivité ; Grossesse ; Sexualité ; Mariage ",
        "calendrierPhysique": "18/11 - 22/11",
        "calendrierEmotionnel": "24/02 00:00 - 23:5908 /05 00:00 - 23:5922 /07 00:00 - 23:5905 /10 00:00 - 23:5916 /12 00:00 - 23:59",
        "calendrierIntellectuel": "15:40 - 15:59"
    },
    {
        "numero": "49",
        "nom": "Vehuel",
        "qualites": "Élévation vers la Grandeur et la Sagesse Divines ; Pratique de la méditation, de la visualisation et de la récitation de mantras ; Faculté d’accéder aux mondes parallèles, d’entrer en contact avec leurs habitants et/ou avec des personnes décédées ; Exaltation et glorification du Divin ; Illumination ; Détachement de la matière ; Élévation par le service ; Grande âme qui consacre sa vie à des causes bénéfiques pour l’humanité ; Touche les grands personnages ; Élabore le germe de la pensée humaine ; Mentalité sensible et généreuse ; Source d’inspiration ; Altruisme, diplomatie ; Libère de l’emprise des désirs instinctifs ; Sentiments de fraternité, aide humanitaire ; Aspiration à ce qui est élevé ; Grand écrivain ; Grand dévouement aux autres ",
        "distorsions": "Difficultés à s’élever et à manifester la sagesse - Complexes d’infériorité et de supériorité - Extrémisme spirituel ou manque de spiritualité - Ne médite pas - Athéisme - Abaissement, asservissement aux désirs matériels - Égoïsme, hypocrisie, absence de principes - S’oppose aux sentiments de fraternité - Écrivain critique, personne qui a une influence négative - Passion, haine - Fuite - Peur de la matière ",
        "situations": "Béatitude ; Élévation, sublimation ",
        "calendrierPhysique": "23/11 - 27/11",
        "calendrierEmotionnel": "25 /02 00:00 - 23:5909 /05 00:00 - 23:5923 /07 00:00 - 23:5906 /10 00:00 - 23:5917 /12 00:00 - 23:59",
        "calendrierIntellectuel": "16:00 - 16:19"
    },
    {
        "numero": "50",
        "nom": "Daniel",
        "qualites": "Éloquence, don oratoire ; Grande faculté de communiquer et d’inspirer les autres ; Leadership, aptitude à annoncer et à expliquer des décisions importantes ; Structuration efficace, bien réfléchie et réalisée avec amabilité et gentillesse ; Faculté d’exprimer les choses de façon belle et agréable, de parler avec art pour ne blesser personne ; Discours qui atténue la rigueur d’une vérité ; Bonté, beauté, harmonie ; Aide à voir clair ; Aide à voir les événements tels qu’ils sont et à prendre les décisions les plus appropriées ; Permet de se détacher de la matière afin de percevoir la Vérité dans son essence ; Capacité de matérialiser les pensées à travers les actes ; Discours, chant, musique et les arts en général ",
        "distorsions": "Problèmes en rapport avec la communication - Éloquence orientée vers l’obtention de bénéfices personnels - Enjôleur, trompeur - Parle bien pour embobiner les crédules, les naïfs - Difficultés d’élocution - Dégénérescence du langage - Égoïsme et problèmes d’égo - Affaires louches, manigance - Vit de moyens illicites - Manipule pour s’assurer l’appui de personnes influentes - Discours, musique et arts négatifs - Se cache derrière des masques et/ou emploie différents masques pour atteindre ses buts ",
        "situations": "Communication ; Élocution, problèmes d'élocution ; Improvisation ; Reins ; Bouche ; Musique ; Orateur ; Chant, Chanter ; Bégaiement ; Gorge ; Voix ",
        "calendrierPhysique": "28/11 - 02/12",
        "calendrierEmotionnel": "26/02 00:00 - 23:5910 /05 00:00 - 23:5924 /07 00:00 - 23:5907 /10 00:00 - 23:5918 /12 00:00 - 23:59",
        "calendrierIntellectuel": "16:20 - 16:39"
    },
    {
        "numero": "51",
        "nom": "Hahasiah",
        "qualites": "Médecine universelle ; Relié à toutes les professions médicales (médecine, soins infirmiers, ensemble des thérapies, neurobiologie, neurotechnologie, etc.) ; Capacité de compréhension globale et multidimensionnelle ; Permet de déceler et d’identifier la cause des maux ; Grand guérisseur, porteur de remèdes universels, conduit vers la guérison véritable, qui touche tous les plans ; Bonté infinie, service inconditionnel ; Accorde la pierre philosophale ; Patron de la Haute Science ; Donne accès à la Vérité qui permet de comprendre la dynamique de l’Univers ; Expert en connaissances ésotériques (Kabbale, alchimie, métaphysique, etc.) ; Véritable mage, âme élevée ",
        "distorsions": "Médecine qui se limite à traiter les symptômes et les douleurs physiques, sans chercher à découvrir et à comprendre les causes profondes des maladies ; ou charlatans et pseudo-thérapeutes qui profitent de la naïveté et de l’ignorance des gens - Enjôleur, trompeur, manipulateur abusant de la bonne foi des autres - Manque de connaissance et de conscience spirituelles dans le milieu des professions médicales - Utilise la médecine uniquement pour s’enrichir matériellement, recherche de pouvoir, ambition - Victime d’escroquerie - Illusion - Science sans conscience ",
        "situations": "Béatitude ; Élévation, sublimation ",
        "calendrierPhysique": "03/12 - 07/12",
        "calendrierEmotionnel": "27/02 00:00 - 23:5911 /05 00:00 - 23:5925 /07 00:00 - 23:5926 /07 00:00 - 12:0008 /10 00:00 - 23:5919 /12 00:00 - 23:59",
        "calendrierIntellectuel": "16:40 - 16:59"
    },
    {
        "numero": "52",
        "nom": "Imamiah",
        "qualites": "Facilité à reconnaître ses erreurs ; Permet d’expier, de payer, de réparer ses erreurs (ses karmas) ; Exécution aisée des travaux difficiles ; Courage, ardeur, grande vigueur et force émotionnelle ; Grande capacité de prendre soin d’autrui, de consoler, d’aider et de soutenir les autres dans des situations difficiles ; Charisme, leadership ; Vie sociale harmonieuse ; Fait la paix avec ses ennemis ; Libère des prisons intérieures ; Fidèle serviteur ; Humilité, simplicité, patience ",
        "distorsions": "Problèmes en rapport avec la communication - Éloquence orientée vers l’obtention de bénéfices personnels - Enjôleur, trompeur - Parle bien pour embobiner les crédules, les naïfs - Difficultés d’élocution - Dégénérescence du langage - Égoïsme et problèmes d’égo - Affaires louches, manigance - Vit de moyens illicites - Manipule pour s’assurer l’appui de personnes influentes - Discours, musique et arts négatifs - Se cache derrière des masques et/ou emploie différents masques pour atteindre ses buts ",
        "situations": "Force de volonté ; Aide à résoudre le karma ; Prisonniers ; Réincarnation ",
        "calendrierPhysique": "08/12 - 12/12",
        "calendrierEmotionnel": "28/02 00:00 - 29 /02 23:5912 /05 00:00 - 23:5926 /07 12:00 - 27 /07 23:5909 /10 00:00 - 23:5920 /12 00:00 - 23:59",
        "calendrierIntellectuel": "17:00 - 17:19"
    },
    {
        "numero": "53",
        "nom": "Nanael",
        "qualites": "Communication spirituelle ; Inspire à la méditation ; Connaissance des sciences abstraites et de la philosophie ; S’intéresse à la vie spirituelle et à l’enseignement ; Fasciné par la contemplation des Mondes Supérieurs ; Mysticisme ; Aime la solitude et les états méditatifs ; Facilite la communication avec le Divin ",
        "distorsions": "Communication spirituelle négative, extrémisme spirituel, essaie de persuader et d’imposer ses croyances aux autres - Non-respect du rythme d’évolution d’autrui - Veut convaincre et sauver tout le monde - Difficulté à méditer - Personne abstraite, tendance à fuir la réalité concrète, comportement autistique - Rejette la connaissance et la communication spirituelles - Ignorance - Se trompe souvent - Apprend difficilement - Peut entrer dans les ordres par peur d’affronter la vie - Difficulté à réaliser ses objectifs et à communiquer - Peur face aux tâches quotidiennes - Sentiment d’échec - Enseigne la spiritualité sans avoir acquis la Connaissance - Recherche de pouvoir spirituel - Humeur mélancolique, isolement - Célibat égoïste - Difficulté à vivre en couple ",
        "situations": "Communication ; Chant, Chanter ",
        "calendrierPhysique": "13/12 - 16/12",
        "calendrierEmotionnel": "01/03 00:00 - 23:5913 /05 00:00 - 23:5928 /07 00:00 - 23:5910 /10 00:00 - 23:5921 /12 00:00 - 23:59",
        "calendrierIntellectuel": "17:20 - 17:39"
    },
    {
        "numero": "54",
        "nom": "Nithael",
        "qualites": "Éternelle jeunesse ; Beauté, grâce, raffinement ; Synchronicité, stabilité ; Hospitalité, accueil chaleureux ; Talents artistiques et esthétiques ; Célébrité, prestige ; Candeur de l’enfant, fraîcheur ; Guérison ; Légitimité successorale, héritage ",
        "distorsions": "Peur de vieillir - Utilise la séduction pour atteindre ses objectifs - Axé sur la beauté extérieure et sur le paraître - Sentiments d’infériorité et de supériorité - Luxure, ambition, admiration aveugle, idolâtrie - Veut plaire à tout le monde - Dépendance affective - Possessivité - Illégitimité - Renversement, conspiration permanente - Attitude qui ne correspond pas aux paroles - Maladie, accident, ruine - Situation instable - Prend pour acquis - Boulimie, anorexie ",
        "situations": "Beauté ; Célébrité, renommée ; Embellir ; Esthétique ; Féminité ; Chirurgie ; Jeunesse ",
        "calendrierPhysique": "17/12 - 21/12",
        "calendrierEmotionnel": "02/03 00:00 - 23:5914 /05 00:00 - 23:5929 /07 00:00 - 23:5911 /10 00:00 - 23:5922 /12 00:00 - 23:59",
        "calendrierIntellectuel": "17:40 - 17:59"
    },
    {
        "numero": "55",
        "nom": "Mebahiah",
        "qualites": "Lucidité intellectuelle ; Idées claires qui permettent la bonté et la bienveillance ; Compréhension par les sens ; Ajuste et réglemente les désirs ; Harmonisation du comportement ; Sens du devoir et des responsabilités ; Ouvre le coeur avec discernement ; Consolation qui naît de la compréhension ; Communique le mystère de la Morale à l’intellect ; Expérience spirituelle profonde et mystique ; Exemple de Morale, conduite exemplaire, engagement ; Aptitude à rectifier sur le plan collectif ",
        "distorsions": "Logique excessive, esprit d’analyse desséchant - Manque de lucidité, opacité mentale - Mensonge - Perfectionniste insatisfait - Complexes de supériorité et d’infériorité - Difficulté à exprimer ses émotions, reniement, négation de tout élan sentimental - Détruit la spiritualité - Agit contre les principes de la Morale - Ne s’intéresse qu’aux choses matérielles - Échec - Méfiance, opposition, combat les pensées positives - Personne capricieuse, égoïste, focalisée de manière excessive sur son apparence et la beauté extérieure, et entretenant une harmonie de façade - Manque de gentillesse et d’amour dû à un esprit trop rationnel et à de faux concepts ",
        "situations": "Consolation ; Discernement, difficulté à discerner le bien du mal ; Lucidité ; Expériences Mystiques ",
        "calendrierPhysique": "22/12 - 26/12",
        "calendrierEmotionnel": "03/03 00:00 - 23:5915 /05 00:00 - 23:5930 /07 00:00 - 23:5912 /10 00:00 - 23:5923 /12 00:00 - 23:59",
        "calendrierIntellectuel": "18:00 - 18:19"
    },
    {
        "numero": "56",
        "nom": "Poyel",
        "qualites": "Fortune, soutien ; Modestie, simplicité, altruisme ; Apporte les cadeaux de la Providence ; Fortune sur tous les plans ; Créateur d’idées et d’ambiances positives ; Talents, renommée et célébrité vécus avec humilité ; Santé ; Estimé de tous ; Facilité d’élocution, s’exprime clairement et simplement ; Humeur agréable ; Espoir, optimisme ; Humour ",
        "distorsions": "Pauvreté, problèmes relatifs à l’abondance, manque de ressources, de soutien et/ou de modestie, de simplicité et d’altruisme - Mauvaise utilisation des ressources, exagération, gaspillage, plaisirs mondains - Philosophie et mode de vie matérialistes - Orgueil, ambition, veut s’élever au-dessus des autres - Vantardise, étalage de la richesse matérielle - Sentiments d’infériorité et de supériorité - Critique, polémique, mépris, abaisse les autres, inhibition, médiocrité - Absence de bonheur, personne malheureuse, mauvaise humeur - Maladie - Problèmes d’élocution - Blagues derrière lesquelles se cachent des besoins et des jugements - Fausse joie, rire et sourire non authentiques ",
        "situations": "Sentiment d'être abandonné ; Cancer ; Féminité ; Générosité / Douceur / Bonté ; Humilité ; Humour / Rire ; Modestie ; Orateur ; Richesse (à tous les plans) ; Timidité ; Bégaiement ; Soutien ",
        "calendrierPhysique": "27/12 - 31/12",
        "calendrierEmotionnel": "04/03 00:00 - 23:5916 /05 00:00 - 23:59 31 /07 00:00 - 23:5913 /10 00:00 - 23:5924 /12 00:00 - 23:59",
        "calendrierIntellectuel": "18:20 - 18:39"
    },
    {
        "numero": "57",
        "nom": "Nemamiah",
        "qualites": "Discernement ; Capacité de comprendre par la simple observation ; Mental privilégié, pouvoir d’anticipation, prévoyance ; Dévoile la cause des problèmes ; Génie en stratégie, force de décision ; Procure le sens de l’action ; Dévouement aux grandes causes par ses idées ; Renonce aux privilèges matériels pour se vouer à sa mission ; Grandeur d’âme, noblesse d’esprit ; Non-attachement ; Sentiment de liberté ; Libère les prisonniers ; Faculté de comprendre son plan de vie et celui des autres ",
        "distorsions": "Manque de discernement, de compréhension profonde et de vision globale, tendance à se perdre dans les détails - Mentalité sombre et sans principes - Vie embrouillée et obscure - Problèmes relationnels, désaccord, discorde - Difficultés de communication, ne s’ouvre pas facilement à l’autre - Manque de liberté d’expression et de liberté en général - Trahison, lâcheté - Indécis, irrésolu - Naïveté, tendance à croire n’importe qui et n’importe quoi - Reste endormi dans la routine - Ne s’engage pas, ne s’implique pas dans l’action - Prisonnier dans la psyché - Fuit l’expérimentation et le concret - Maladie et fatigue chroniques ",
        "situations": "Discernement, difficulté à discerner le bien du mal ; Prisonniers ",
        "calendrierPhysique": "01/01 - 05/01 ",
        "calendrierEmotionnel": "05/03 00:00 - 23:5917 /05 00:00 - 23:5901 /08 00:00 - 23:5914 /10 00:00 - 23:5925 /12 00:00 - 23:59",
        "calendrierIntellectuel": "18:40 - 18:59"
    },
    {
        "numero": "58",
        "nom": "Yeialel",
        "qualites": "Force mentale, haut niveau d’intelligence ; Grande logique, aptitude à discipliner ses pensées ; Aptitude à discerner avec rigueur ; Développe les facultés mentales ; Favorise les prises de conscience, la lucidité, la clairvoyance ; Faculté de concentration, recherche de précision, compétence, patience ; Force bénéfique à l’utilisation des ordinateurs et à la programmation ; Neurotechnologie ; Maîtrise les passions et les impulsions émotives ; Franchise, bravoure ; Sens de la justice et de l’ordre, rigueur, loyauté inconditionnelle ; Compréhension des Lois et des Structures Divines ",
        "distorsions": "Essaie de contrôler, pense de manière excessive, obsessions, focalisation exagérée sur les soucis - Problèmes en lien avec l’amour et les émotions en général - Manque d’intelligence, difficulté à se concentrer, à focaliser son attention, son énergie, son intention ; ou utilisation abusive de l’intelligence et des facultés intellectuelles - Se perd dans les mondes virtuels (jeux vidéo, Internet, réseaux sociaux, etc.) - Mauvaise utilisation de la neurotechnologie - Perfectionnisme à outrance - Impose ses idées par la ruse, la manipulation - Mauvaises intentions, mensonge, trahison - Colère, vengeance - Abus de pouvoir, crimes - Obstination, entêtement, illogisme - Rigidité, sévérité - Morosité, tristesse, pessimisme - Ne croit pas en Dieu, en une Force Supérieure, athéisme - Mégalomanie - Vie abstraite - Personne excessivement rationnelle et logique, qui croit uniquement ce qui peut être prouvé concrètement - Décalages entre l’intellect, les émotions et les aspects physiques ",
        "situations": "Activité Cérébrale ; Concentration, capacité de se concentrer ; Yeux / Myopie; Lucidité ; Mental, Problèmes Mentaux ; Programmation, programmer",
        "calendrierPhysique": "06/01 - 10/01",
        "calendrierEmotionnel": "06/03 00:00 - 23:5918 /05 00:00 - 23:59 02 /08 00:00 - 23:5915 /10 00:00 - 23:5926 /12 00:00 - 23:59",
        "calendrierIntellectuel": "19:00 - 19:19"
    },
    {
        "numero": "59",
        "nom": "Harahel",
        "qualites": "Richesse intellectuelle, accès à la Connaissance ; Capacité de matérialiser par le biais de la technologie et de programmations avancées ; Diffuse la Bonté, la Beauté et la Vérité ; Intelligence équilibrée dans tous les domaines ; Aime s’instruire, apprend avec facilité ; Créativité intellectuelle, intelligence pratique ; Fécondité, productivité sur tous les plans ; Enfants soumis et respectueux envers leurs parents ; Capacité de faire fortune grâce à ses qualités intellectuelles ; Écriture, journalisme, édition et imprimerie ",
        "distorsions": "Manque de connaissances, d’idées ou d’intelligence - Passe trop de temps devant les ordinateurs, vit à travers les mondes virtuels, n’est pas suffisamment ancré dans la vie terrestre, manque d’actions concrètes, d’exercice physique et de contact avec la réalité physique - Aberrations intellectuelles - Écrits destructeurs, diffusion et influence négatives pour l’humanité - Opacité mentale, incompréhension - Stérilité, improductivité sur tous les plans - Enfants rebelles et irrespectueux - Incendie, brûle tout sur son passage - Ennemi de la Lumière - Projets voués à l’échec - Manipulation médiatique à des fins personnelles - Fraude ",
        "situations": "Publicité ; Intelligence ; Mental, Problèmes Mentaux ; Musique ; Télévision ; Écriture, écrivain ",
        "calendrierPhysique": "11/01 - 15/01",
        "calendrierEmotionnel": "07/03 00:00 - 23:5919 /05 00:00 - 23:59 20 /05 00:00 - 12:0003 /08 00:00 - 23:5916 /10 00:00 - 23:5927 /12 00:00 - 18:00",
        "calendrierIntellectuel": "19:20 - 19:39"
    },
    {
        "numero": "60",
        "nom": "Mitzrael",
        "qualites": "Réparation ; Compréhension de l’obéissance et de l’autorité ; Rectification ; Facilite l’exercice de la psychologie et de la psychiatrie ; Grand talent en neurobiologie, neurotechnologie et dans le domaine de la technologie en général ; Guérison des maladies mentales ; Réparation par la conscientisation ; Travail et harmonisation intellectuels ; Réunification des plans physique, émotionnel, mental et spirituel ; Simplicité ",
        "distorsions": "Difficulté à accepter ses erreurs et à réparer, reconstruire sa vie ; tendance à abandonner, à baisser les bras - Peur du changement, non-acceptation des karmas qu’on a générés, refus d’évoluer - Fragilité causée par des décalages - Problèmes avec l’autorité, avec le père ou la personne qui tient le rôle de père, ou avec son supérieur, son chef - Insubordination, désobéissance - Vindicatif, critiqueur, compliqué - Manque d’aide, d’entraide et de coopération, mentalité du chacun-pour-soi - Révolte, rébellion, persécution - Maladies mentales (paranoïa, folie, schizophrénie, etc.) - Fatigue chronique, migraine - Médecine sans conscience ",
        "situations": "Autoritarisme ; Bronches ; Dyslexie ; Examens, études ; Père ; Apprentissage des Langues ; Méningite ; Mental, Problèmes Mentaux ; Nervosité, tension nerveuse, l'excitabilité ; Psychologie ; Rectifier ; Réparation, réparer ; Schizophrénie ; Sinusite ",
        "calendrierPhysique": "16/01 - 20/01",
        "calendrierEmotionnel": "08/03 00:00 - 23:5920 /05 12:00 - 23:5921 /05 00:00 - 23:5904 /08 00:00 - 23:5917 /10 00:00 - 23:5927 /12 18:00 - 23:59",
        "calendrierIntellectuel": "19:40 - 19:59"
    },
    {
        "numero": "61",
        "nom": "Umabel",
        "qualites": "Amitié, affinité ; Étude et compréhension des résonances ; Aptitude pour la technologie et la neurotechnologie ; Aide à pénétrer le subconscient et l’inconscient pour connaître les vraies motivations ; Physique, astronomie, astrologie ; Fait comprendre les analogies entre l’Univers et le monde terrestre et entre tous les plans de la Création ; Dévoile les secrets des règnes minéral, végétal et animal ; Aide à développer la conscience ; Capacité d’enseigner ce qu’on a appris ; Instructeur, enseignant ; Permet de connaître l’inconnu à travers le connu ",
        "distorsions": "Problèmes en lien avec l’amitié et les affinités - Craint la solitude, a peur d’être seul - Veut plaire aux autres - Recherche d’appréciation et de renommée - Incompréhension de la Loi de la résonance et du principe d’attraction/répulsion - Libertinage - Cœur solitaire, difficulté à se faire des amis, isolement, auto-aliénation - Problèmes avec la mère - Retour au passé, nostalgie, attachement à des concepts dépassés, révolus - Problèmes en lien avec la technologie et la neurotechnologie - Narcissisme - Marginalité, agit contre l’ordre naturel - Problèmes de drogue - Ignorance des analogies entre les différents plans de la Création - Science sans conscience - Difficulté à transmettre ce que l’on a appris - Faux ou mauvais enseignant, instructeur, professeur ",
        "situations": "Affinité, Amitié ; Astrologie ; Conscience ; Éducation ; Fraternité, fraterniser ; Renforcement des liens ; Mère ",
        "calendrierPhysique": "21/01 - 25/01 ",
        "calendrierEmotionnel": "09/03 00:00 - 23:5922 /05 00:00 - 23:5905 /08 00:00 - 23:5918 /10 00:00 - 23:5928 /12 00:00 - 23:59",
        "calendrierIntellectuel": "20:00 - 20:19"
    },
    {
        "numero": "62",
        "nom": "Iahhel",
        "qualites": "Connaissance retrouvée ; Philosophe, mystique ; Illumination ; Procure la Sagesse et le sens des responsabilités ; Bénéfique aux retraites, facilite l’intériorisation, l’introspection positive, constructive, fructueuse ; Solitude, tranquillité ; Modestie, douceur ; Favorise la rencontre de l’homme et de la femme ; Sexualité vécue divinement, plaisirs purs ; Paiement des dettes karmiques ; Pacifisme ; Affine les sens jusqu’aux plus subtils (clairvoyance, clairsentience, clairaudience) ; Créateur d’ambiances positives, harmonieuses ; Aime la qualité, la beauté, la poésie ; Art culinaire ",
        "distorsions": "Problèmes dus à un manque de Connaissance, ou tendance à s’approprier la Connaissance - Escroc, imposteur, faux savant - Besoin de plaisir, scandales, luxe, vanité - Personne matérialiste, ambitieuse - Problèmes de couple, difficultés dans la relation intime, séparation, divorce - Manque de modestie, de gentillesse, de douceur - Besoin de l’approbation des autres - Jalousie, envie - Créateur de conflits, agressivité - Inconstance, agitation, incapacité de rester seul et tranquille - Isolement ",
        "situations": "Divorce ; Instruction ; Luxure ; Mariage ",
        "calendrierPhysique": "26/01 - 30/01",
        "calendrierEmotionnel": "10/03 00:00 - 23:5923 /05 00:00 - 23:5906 /08 00:00 - 23:5919 /10 00:00 - 23:5929 /12 00:00 - 23:59",
        "calendrierIntellectuel": "20:20 - 20:39"
    },
    {
        "numero": "63",
        "nom": "Anauel",
        "qualites": "Perception de l’Unité ; Succès dans les relations humaines, facilité à communiquer ; Intelligence pratique, logique, vision globale ; Initiateur de projets et d’entreprises voués au service du Divin ; Compréhension juste du concept de l’argent et des échanges ; Aptitude à matérialiser de manière juste, équitable et en respectant les étapes ; Faculté de générer une grande abondance avec de nouveaux concepts, de nouvelles idées et technologies ; Administrateur, coordonnateur, planificateur, visionnaire ; Commerçant, banquier, agent d’affaires, industriel, entrepreneur au service du Divin ; Expert dans la compréhension des mentalités et cultures ; Maîtrise des émotions ; Grand leader, inspirateur ; Citoyen de l’Univers ",
        "distorsions": "Se perd dans les détails, accorde trop d’importance à l’argent, égoïsme - Incapacité de créer l’unité dans un groupe - Difficulté à générer l’abondance, à échanger avec les autres, à réussir en affaires - Problèmes avec les nouveaux concepts, les nouvelles idées et technologies - Manque de connaissances et de respect à l’égard des autres mentalités et cultures - Complexe de supériorité sur le plan intellectuel, croit tout savoir, esprit arrogant - Difficulté à guider et à inspirer les autres - Limitations au niveau des voyages, incapacité à obtenir le Passeport universel et l’accès aux mondes parallèles - Absence de sagesse dans les affaires - Manque de bon sens, de vision et de compréhension globales - Corruption - Prêt à tout pour faire de l’argent - Faux raisonnement, manipulé par les désirs - Excès de prodigalité (dépense plus qu’il ne possède), gaspillage, ruine - Esprit limité, trop critique, excessivement rationnel - Froide appréciation - Difficulté ou refus de croire en une Puissance Supérieure, athéisme ",
        "situations": "Communication ; mains ; Négociation ; Organisation ; Unité ; Amour Universelle, Amour Suprême ",
        "calendrierPhysique": "31/01 - 04/02",
        "calendrierEmotionnel": "11/03 00:00 - 23:5924 /05 00:00 - 23:59 07 /08 00:00 - 23:5920 /10 00:00 - 23:5930 /12 00:00 - 23:59",
        "calendrierIntellectuel": "20:40 - 20:59"
    },
    {
        "numero": "64",
        "nom": "Mehiel",
        "qualites": "Vivification, inspiration ; Vie intense, féconde et productive ; Intelligence, imagination, réceptivité et compréhension profonde ; Aide à trouver des solutions pratiques et innovatrices ; Développe les facultés mentales en harmonie avec l’imagination ; Force bénéfique à l’activité intellectuelle, aux ordinateurs et à la programmation ; Aide à comprendre la corrélation entre la science des rêves et la technologie ; Touche l’écriture, l’édition, l’imprimerie, les maisons de diffusion, les librairies et les orateurs, ainsi que les émissions de télévision et de radio ; Favorise le développement technologique ; Aide à réfléchir sur l’expérience personnelle et à la comprendre ; Antidote contre les forces de l’abîme ",
        "distorsions": "Manque d’énergie, incapacité de penser ou d’entreprendre, faire quelque chose ; ou hyperactivité, surexcitation, souhait de plaire et d’être apprécié, reconnu - Vie stérile, improductive, difficulté à créer et/ou à réaliser des projets - Manque d’intensité et d’inspiration, problèmes au niveau de la créativité et de l’imagination - Excès ou absence de buts, d’objectifs, d’aspirations - Contradiction, critique, polémique - Déformation de la réalité et complaisance dans l’illusion - Tyrannie, mégalomanie, oppression, fausseté - Comportement destructif, essaie de forcer le Destin - Ne comprend pas la mise en scène de sa vie - Excès de rationalité - Joue un rôle, manque d’authenticité - Problèmes de personnalité ",
        "situations": "Librairie ; Critique ; Fatique, fatigue chronique ; Improvisation ; Impression ; Programmation, programmer ; Enregistrement ; Scénario ; Télévision ; Thymus ; Écriture, écrivain ",
        "calendrierPhysique": "05/02 - 09/02",
        "calendrierEmotionnel": "12/03 00:00 - 23:5925 /05 00:00 - 23:5908 /08 00:00 - 23:5921 /10 00:00 - 23:59 31 /12 00:00 - 23:59",
        "calendrierIntellectuel": "21:00 - 21:19"
    },
    {
        "numero": "65",
        "nom": "Damabiah",
        "qualites": "Fontaine de Sagesse ; Pureté, douceur, bonté ; Rayonne les grandes valeurs spirituelles telles que l’altruisme, le dévouement, la générosité, le non-attachement et l’amour inconditionnel ; Fait avancer par la voie facile ; Réussite dans les entreprises utiles à la communauté ; Relié à l’eau (sources, cours d’eau, mers...), aux émotions et aux sentiments ; Personne providentielle qui peut résoudre des situations compromises ",
        "distorsions": "Manque de sagesse, de pureté, de bonté, de gentillesse, de dévotion, de générosité, d’altruisme - Personne égoïste, centrée sur elle-même - Échec dans les affaires dû à un manque d’amour et de respect pour la communauté - Choisit la voie difficile pour faire les choses - N’est pas en mesure de résoudre des problèmes - Comportements excessifs, compulsifs - Émotions tumultueuses, sentiments instables, ou puritanisme - Colère, agressivité - Tempête, naufrage - Fatalisme ",
        "situations": "Altruisme ; Saignement (s) ; Dévotion ; Hémorragie ; Drainage Lymphatique ; Menstruation ; Pureté ; Naufrage ",
        "calendrierPhysique": "10/02 - 14/02",
        "calendrierEmotionnel": "01/01 00:00 - 23:5913/03 00:00 - 23:5926 /05 00:00 - 23:5909 /08 00:00 - 23:5922 /10 00:00 - 23:59",
        "calendrierIntellectuel": "21:20 - 21:39"
    },
    {
        "numero": "66",
        "nom": "Manakel",
        "qualites": "Connaissance du bien et du mal ; Transcendance des peurs ; Stabilité, confiance ; Aide à la création d’une belle vie ; Haute moralité ; Apaise l’être, guérit les maladies ; Amabilité, bonté, bienveillance ; Libère le potentiel enfoui dans les profondeurs ; Neurotechnologie ; Rêves, songes, Haute Initiation / Réunification des qualités du corps et de l’esprit ",
        "distorsions": "Joue avec les forces négatives, ne comprend pas que le mal attire le mal - Réceptivité aux forces obscures - Instabilité, manque de foi et de confiance, tendances suicidaires - Potentiel piégé dans des mémoires négatives - Vieille âme qui ne veut pas changer, paresse - Sentiments de supériorité et d’infériorité - Attitudes mégalomanes - Manipulateur dangereux et machiavélique, est prêt à tout pour arriver à ses fins, absence de principes et de valeurs altruistes - Perturbations physiques et morales - Recherche des jouissances uniquement matérielles et du prestige social - Pour une femme : manifestation tardive de sa personnalité - Pour un homme : rencontre tardive avec la femme - Amitiés dangereuses - Esprit destructeur, impulsivité - Ne tient pas ses promesses - Refus d’appliquer la Connaissance - Usage abusif, mauvais et malveillant de la technologie - Colère envers Dieu, révolte ",
        "situations": "Acné ; Amabilité ; Refroidissement, rhume / Arthrite, arthrose, rhumatisme ; Rêves, interprétations des rêves ; Dents / Gencives ; Maladie incurable / Calculs Rénaux ; Malveillance ; Stabilité ; Tentations ",
        "calendrierPhysique": "15/02 - 19/02",
        "calendrierEmotionnel": "02/01 00:00 - 23:5914/03 00:00 - 23:59 27 /05 00:00 - 23:5910 /08 00:00 - 23:5923 /10 00:00 - 23:59",
        "calendrierIntellectuel": "21:40 - 21:59"
    },
    {
        "numero": "67",
        "nom": "Eyael",
        "qualites": "Sublimation ; Science des mélanges et des échanges ; Transsubstantiation (changement d’une substance en une autre), transformation, mutation, métamorphose, transfiguration, transfert ; Aptitude à comprendre l’Histoire Universelle, capacité de déceler l’origine et la genèse ; Archéologie ; Faculté d’observer, de reconnaître et de comprendre les affinités ; Compréhension de la Loi de la résonance, du fait qu’on attire et crée ce que l’on est ; Connaissances supérieures en chimie, physique, biologie, biotechnologie, neurotechnologie, etc. ; Étude de l’ADN, des cellules, des atomes, des structures fondamentales ; Étude des Hautes Sciences ; Vérité abstraite transformée en vérité concrète ; Art culinaire, peinture, musique ; Joie ; Amour de la solitude ",
        "distorsions": "Transformations et mutations négatives, nuisibles - Manipulations qui génèrent de mauvaises expériences - Excès ou manque au niveau des échanges - Peur des changements - Erreurs, préjugés - Propage des systèmes erronés, faux professeur - Tendance à tout mélanger, à créer de la confusion - Problèmes résultant d’un manque de connaissances en chimie, alchimie, biologie, biotechnologie, etc. - Utilisation abusive de la science - Manque d’éclairage, de morale et de principes - Passe d’une expérience à l’autre sans comprendre - Lourdeur, absorbé par la matière, ne médite pas - Nourriture artificielle, peinture et musique qui exercent une influence négative - Absence de joie, tristesse, inquiétude - Isolement ",
        "situations": "Changement (en général) ; Dormir ",
        "calendrierPhysique": "20/02 - 24/02",
        "calendrierEmotionnel": "03/01 00:00 - 23:5915/03 00:00 - 23:5928 /05 00:00 - 23:5911 /08 00:00 - 23:5924 /10 00:00 - 23:59",
        "calendrierIntellectuel": "22:00 - 22:19"
    },
    {
        "numero": "68",
        "nom": "Habuhiah",
        "qualites": "Guérison ; Touche l’ensemble des professions du do/05ne de la médecine et de la thérapie, y compris les soins et les traitements de guérison énergétiques, métaphysiques et spirituels ; Capacité de restructurer et de réglementer les désirs ; Aide à s’ajuster aux Normes Divines ; Rééquilibre les déphasages et les décalages ; Aptitude à se réharmoniser lorsqu’on n’est plus dans la synchronicité ; Aime la nature, la vie à la campagne et les espaces libres ; Agriculture, récolte, expertise agricole ; Nature fertile, pouvoir créateur ",
        "distorsions": "Difficulté à comprendre la maladie et la guérison due à un manque de connaissances, de sagesse et à l’incompréhension des causes métaphysiques, originelles - Faux guérisseur, charlatan - Personne perdue dans une multitude de besoins et désirs - Décalage, déphasage, manque de synchronicité, difficulté à être au bon endroit au bon moment - Double vie, décalage entre les pensées et les émotions - Déphasage entre d’une part ce que l’on souhaite être et faire, et d’autre part ce que l’on est et ce que l’on fait - Pour les femmes : tendance dominatrice - Pour les hommes : tendance à se laisser dominer par les femmes - Réticence à abandonner les vieux privilèges - Attitude anti-vie - Terre infertile, famine, misère, pollution, invasion d’insectes - Maladies contagieuses, épidémies ",
        "situations": "Acné ; Ambiguité, situation ambiguë ; Anorexie ; Arthrite, arthrose, rhumatisme ; Béatitude ; Boulimie ; Brûlures ; Cancer ; Refroidissements ; Kystes ; Désirs, ajuster et réglementer les désirs ; Maladie, en général ; Dyslexie ; Fièvre ; Fibromyalgie ; Décalage entre pensées et émotions ; Jardinage ; Germes, Microbes ; Gencives ; Récolte ; Santé ; Peau ; Infection, inflammation ; Infertilité, Stérilité ; Ingratitude ; Blessures, Guérison des blessures ; Lois: Respect des lois ; Amour de la nature ; Poumons ; Principes Masculin et Féminin ; Microbes ; Migraines ; Muqueuses ; Arthrose ; Embonpoint ; Douleur ; Parasite ; Sentiments Refoulés ; Responsabilités, fuite des responsabilités ; Rétention ; Réunification des Qualités du Corps et de l'Esprit ; Rhume ; Sinusite ; Stérilité, infertilité ; Synchronisme, synchronicité ; Dents ; Reins ",
        "calendrierPhysique": "25/02 - 29/02",
        "calendrierEmotionnel": "04/01 00:00 - 23:5916/03 00:00 - 23:5929 /05 00:00 - 23:5912 /08 00:00 - 00:5925 /10 00:00 - 23:59",
        "calendrierIntellectuel": "22:20 - 22:39"
    },
    {
        "numero": "69",
        "nom": "Rochel",
        "qualites": "Restitution, accorde à chacun ce qui lui revient ; Retrouve les objets, les sentiments et les pensées perdus ou volés ; Succession, héritage ; Notaire, magistrat ; Intuition ; Étude des Lois et de la Justice ; Étude de l’Histoire ; Archives et Bibliothèque Universelles (Daath) ; Sciences pratiques et théoriques ; Faculté de donner et recevoir avec aisance et facilité ; Administration, comptabilité, secrétariat ; Retrouve le Moi Divin, l’Androgynie Originelle ; Nettoie les contenus karmiques, transforme les karmas ",
        "distorsions": "S’approprie ce qui ne lui appartient pas - Jalousie, possessivité, égoïsme - Relations de couple basées exclusivement sur la sexualité et sur la matière - Abus sexuels, libertinage et relations multiples - Problèmes familiaux - Difficulté en lien avec la succession, l’héritage et le travail des notaires, des magistrats - Problèmes relatifs à l’administration, la gestion des ressources, la comptabilité et le secrétariat - Esprit trop pragmatique, trop focalisé sur l’aspect pratique - Problèmes avec le donner et le recevoir - Usurpation de biens, vol, ruse - Peur existentielle, insécurité - Vampirise, prend l’énergie des autres - Manipulation des faits historiques - Mégalomanie - Problèmes juridiques, injustice flagrante, procès qui n’en finissent plus - Ruine - Manque de réceptivité ou d’émissivité ",
        "situations": "Oubli, oublier ; Héritage ; Objets (perdu) ; Restitution ; Revenir dans son pays natal ; Succession ",
        "calendrierPhysique": "01/03 - 05/03",
        "calendrierEmotionnel": "05/01 00:00 - 23:5917/03 00:00 - 23:59 30 /05 00:00 - 23:5913 /08 00:00 - 23:5926 /10 00:00 - 23:59",
        "calendrierIntellectuel": "22:40 - 22:59"
    },
    {
        "numero": "70",
        "nom": "Jabamiah",
        "qualites": "Alchimie ; Transforme le mal en bien ; Compréhension et application de la Loi de la résonance ; Grande réceptivité et faculté d’aimer dans toutes les circonstances ; Guérison ; Régénère, revivifie, rétablit l’harmonie ; Transforme, transmute en or spirituel ; Transforme la société avec des idées lumineuses ; Maîtrise les instincts ; Guide les premiers pas des défunts dans l’autre monde ; Aide à l’accompagnement des mourants ; Capacité de visiter les mondes parallèles, de comprendre le travail des guides spirituels et d’apprendre comment on devient un guide et un guérisseur spirituels ",
        "distorsions": "Difficulté à transformer, transcender le mal, les énergies, situations et aspects négatifs - Blocage, rétention, problèmes de digestion sur les différents plans, obésité - Problèmes de santé, maladies incurables, difficulté à guérir due à un surplus de mémoires, pensées, émotions et comportements négatifs - Refus ou résistance face à la réceptivité - Problèmes causés par des besoins instinctuels inassouvis - Débordement, réaction excessive, conflit, affrontement - Rejet de l’autre, manque d’amour, accumulation de sentiments négatifs - Tendance à s’embourber, lourdeur, incapacité à se déterminer un objectif - Ignorance de la Loi de la résonance ou refus de l’appliquer - Incompréhension du bien et du mal - Athéisme, incrédulité - Peur des changements et de la mort - Difficulté à accompagner des personnes en phase terminale ou en fin de vie ",
        "situations": "Accompagnement des mourants ; Cancer ; Décès, Mort ; Digestion ; Intestins ; Calculs Rénaux ; Foie ; Mère ; Estomac ; Transformation, transformation du mal en bien ",
        "calendrierPhysique": "06/03 - 10/03",
        "calendrierEmotionnel": "06/01 00:00 - 23:5918/03 00:00 - 23:5931 /05 00:00 - 23:5914 /08 00:00 - 23:5927 /10 00:00 - 23:59",
        "calendrierIntellectuel": "23:00 - 23:19"
    },
    {
        "numero": "71",
        "nom": "Haiaiel",
        "qualites": "Armes Divines ; Discernement (symbole de l’épée) ; Aura lumineuse (symbole du bouclier) ; Protection Divine pour prendre la décision la meilleure, la plus juste ; Intelligence réceptive, esprit protecteur, stratège ; Délivre de ceux qui nous oppriment ; Protège et conduit à la victoire, la bravoure et le courage ; Idées et concepts nouveaux qui peuvent changer le monde ; Réceptivité à l’inspiration Divine ; Développe une grande énergie ; Leadership ",
        "distorsions": "Terroriste, activiste - Manque d’intelligence et d’inspiration - Personne dangereuse qui utilise des forces et des pensées négatives, énergies sombres, magie noire, pactes sataniques - Manipulateur, menteur - Prêt à tout pour gagner, pour atteindre son but - Vindicatif, dictateur, tyran - Discorde, trahison - Fournit des armes pour tuer - Porteur de contradictions intérieures - Rupture (divorce, rupture de contrat, etc.) - Idées criminelles, extrémisme - Excès de rationalité - Non-respect des engagements - Guerre, conflits continus - Gouvernement corrompu ",
        "situations": "Agression ; Audace ; Bravoure ; Courage, bravoure ; Critique ; Discernement, difficulté à discerner le bien du mal ; Force de volonté ; Guerrier (de Lumière) ",
        "calendrierPhysique": "11/03 - 15/03",
        "calendrierEmotionnel": "07/01 00:00 - 23:5919/03 00:00 - 23:59 01 /06 00:00 - 23:5915 /08 00:00 - 23:5928 /10 00:00 - 23:59",
        "calendrierIntellectuel": "23:20 - 23:39"
    },
    {
        "numero": "72",
        "nom": "Mumiah",
        "qualites": "Renaissance ; Grande réceptivité, facultés médiumniques, expériences mystiques ; Nouveau commencement ; Hautes Initiations qui produisent des transformations majeures et amènent de nouvelles connaissances ; Place le germe d’une vie nouvelle ; Compréhension de la Loi de la réincarnation et de la manière dont se manifestent les affinités sur les différents plans ; Début de la transformation et de la mutation angéliques ; Annonce la fin d’un cycle et le début d’un nouveau ; Porteur de conclusion, aide à terminer ce que l’on a commencé ; Réalisation concrète, matérialisation ; Touche la médecine et la santé ; Phase terminale dans laquelle se trouve le germe du renouveau ; Accompagnement des mourants ; Grande expérience de la vie / Ouverture de conscience ",
        "distorsions": "Difficulté à terminer un cycle et/ou à en commencer un nouveau - Peur des expériences mystiques due à un manque de connaissances et de compréhension spirituelles et métaphysiques - Craint les initiations et l’évolution de sa conscience, préférant continuer à vivre avec une conscience ordinaire - Ignorance ou fausse compréhension du principe de la réincarnation et de la vie éternelle, ou refus d’y croire - Désespoir, voie sans issue, horizon bouché, dépression - Tendance à abandonner, à nourrir des ambiances négatives et de vieux schémas - Difficulté à s’ouvrir à la spiritualité et au Divin, à développer une nouvelle conscience et à vivre en accord avec elle, athéisme - Mort inconsciente, suicide - Renie sa propre existence, influence négative - Mauvaise santé, handicap - Écroulement, ruine, perte d’emploi, de conjoint, d’amis, etc. - Passe d’une expérience à l’autre sans comprendre - Cherche à convaincre - Va à l’encontre de l’ordre naturel - Force la matérialisation - Science et connaissance employées sans conscience ",
        "situations": "Accompagnement des mourants / Décès, Mort ; Dépression ; Épuisement ; Fatique, fatigue chronique ; Intestins ; Ouverture Conscience ; Renaissance / Réincarnation ; Suicide ",
        "calendrierPhysique": "16/03 - 20/03",
        "calendrierEmotionnel": "08/01 00:00 - 23:5920/03 00:00 - 23:5902 /06 00:00 - 23:5916 /08 00:00 - 23:5929 /10 00:00 - 23:59",
        "calendrierIntellectuel": "23:40 - 23:59"
    }
  ]