const alphabet = [0, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
const voyelles = ['a', 'e', 'i', 'o', 'u', 'y']
const consonnes = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'z']
/**
 * Retourne un tableau avec le poids 1-9 de chaque lettre
 * Attention cas 18 géré individuellement
 * @param {string} mot 
 */
const calculMot = (mot) => {
    const motTab = mot.split('')
    const tab = motTab.map(lettre => alphabet.indexOf(lettre) > 9 ? alphabet.indexOf(lettre) === 18 ? 9 : alphabet.indexOf(lettre) % 9 : alphabet.indexOf(lettre))
    return tab
}

// Calcul la somme d'un mot selon la méthode 1-9
const calculSommeMot = (mot = []) => {
    if (mot && mot.length > 0) {
        return mot.reduce((accumulateur, currentValue) => accumulateur + currentValue)
    }
    else
        return 0
}

// Calcul la somme d'une date de naissance
const calculSommeNaissance = (mot = []) => {
    if (mot && mot.length > 0) {
        return mot.reduce((accumulateur, currentValue) => parseInt(accumulateur) + parseInt(currentValue))
    }
    else
        return 0
}

/**
 * retourne la somme des chiffres de la date de naissance 
 * @param {string} date 
 */
export const calculNaissanceTab = (date = '') => {
    if (date && date.length > 0) {
        const dateNaissance = date.split('')
        return calculSommeNaissance(dateNaissance)
    }
    return 0
}

export const reduce1a9 = (n) => {
    const nombre = n
    if (nombre < 10)
        return nombre
    else {
        const nombreTab = nombre.toString().split('')
        const reduce = nombreTab.reduce((accumulateur, currentValue) => parseInt(accumulateur) + parseInt(currentValue))
        return reduce1a9(reduce)
    }
}

export const reduce1a9ou11ou22ou33ou44 = (n) => {
    const nombre = n
    if (nombre) {
        if (nombre < 10 || nombre === 11 || nombre === 22 || nombre === 33 || nombre === 44)
            return nombre
        else {
            const nombreTab = nombre.toString().split('')
            const reduce = nombreTab.reduce((accumulateur, currentValue) => parseInt(accumulateur) + parseInt(currentValue))
            return reduce1a9(reduce)
        }
    }
}

export const calcul1a9mot = (mot) => {
    return reduce1a9(calculSommeMot(calculMot(mot)))
}

export const calculVoyelle = (mot) => {
    const motTab = mot.split('')
    const res = motTab.map((lettre) => voyelles.find(item => item === lettre)).filter(lettre => lettre !== undefined)
    const res1 = calculSommeMot(res)
    return res1 !== 0 ? calcul1a9mot(res1) : 'Entrer le nom prénom'
}

export const calculConsonnes = (mot) => {
    const motTab = mot.split('')
    const res = motTab.map((lettre) => consonnes.find(item => item === lettre)).filter(lettre => lettre !== undefined)
    const res1 = calculSommeMot(res)
    return res1 !== 0 ? calcul1a9mot(res1) : 'Entrer le nom prénom'
}

const isLeapYear = (year) => {
    if ((year & 3) !== 0) return false;
    return ((year % 100) !== 0 || (year % 400) === 0);
};

export const signeAstrologique = (date, month) => {
    let value = ''
    if ((month === 1 && date >= 20) || (month === 2 && date <= 18)) { return value = "Verseau" }
    if ((month === 2 && date >= 19) || (month === 3 && date <= 20)) { return value = "Poissons" }
    if ((month === 3 && date >= 21) || (month === 4 && date <= 19)) { return value = "Bélier" }
    if ((month === 4 && date >= 20) || (month === 5 && date <= 20)) { return value = "Taureau" }
    if ((month === 5 && date >= 21) || (month === 6 && date <= 21)) { return value = "Gémeaux" }
    if ((month === 6 && date >= 22) || (month === 7 && date <= 22)) { return value = "Cancer" }
    if ((month === 7 && date >= 23) || (month === 8 && date <= 22)) { return value = "Lion" }
    if ((month === 8 && date >= 23) || (month === 9 && date <= 22)) { return value = "Vièrge" }
    if ((month === 9 && date >= 23) || (month === 10 && date <= 22)) { return value = "Balance" }
    if ((month === 10 && date >= 23) || (month === 11 && date <= 21)) { return value = "Scorpion" }
    if ((month === 11 && date >= 22) || (month === 12 && date <= 21)) { return value = "Sagittaire" }
    if ((month === 12 && date >= 22) || (month === 1 && date <= 19)) { return value = "Capricorne" }
    else
        return value
}

export const compareDate = (jour, mois, c) => {

    if (c && c.length > 0) {
        let chaine = c.split('-')
        const debut = chaine[0].split('/')
        const fin = chaine[1].split('/')
        //[debut,fin] = chaine.split('/')
        
        const date1 = new Date('2000', mois, jour).getTime()
        const date2 = new Date('2000', debut[1], debut[0]).getTime()
        const date3 = new Date('2000', fin[1], fin[0]).getTime()
        if (date1 >= date2 && date1 <= date3)
            return true
        else return false
    }
}


/**
 * Retourne un tableau avec le poids de chaque nombre 1-9
 * @param {*} string 
 */
export const calculNbDominants = (string) => {
    const stringTab = string.split('')
    const items = stringTab.map(lettre => alphabet.indexOf(lettre) > 9 ? alphabet.indexOf(lettre) === 18 ? 9 : alphabet.indexOf(lettre) % 9 : alphabet.indexOf(lettre))
    return countOccurences(items);
}

/**
 * Compte le nombre d'occurence des chiffres d'un tableau
 * @param {*} tab 
 */
function countOccurences(tab) {
    const result = []
    tab.forEach(function (elem) {
        if (elem in result) {
            result[elem] = ++result[elem]
        }
        else {
            result[elem] = 1
        }
    })
    return result
}

/**
 * Retourne un tableau avec le poids de chaque nombre 1-9
 * @param {*} string 
 */
export const calculNbManquants = (string) => {
    const stringTab = string.split('')
    const items = stringTab.map(lettre => alphabet.indexOf(lettre) > 9 ? alphabet.indexOf(lettre) === 18 ? 9 : alphabet.indexOf(lettre) % 9 : alphabet.indexOf(lettre))
    return countOccurences(items);
}