import { Types } from './action';

// create initial state for reducers
const INIT_STATE = {
    name: '',
    prenom: '',
    birthday: {
        jour:'',
        mois:'',
        annee:'',
        heure:'',
        minute:''
    },
    zodiac: '',
    element:'',
    ange:''
}

// reducer function to transform state
export default function NumerologieReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case Types.GET_NAME: {  
            return state
        }
        case Types.SET_NAME: {
            const newItems = Object.assign({},state,action.payload)
            return newItems
        }
        case Types.SET_DATE: {
            if (action.payload.birthday !== null && action.payload.birthday !== 'Invalid Date') {
                const newItems = Object.assign({},state,{birthday:{
                    jour: action.payload.birthday.getDate(),
                    mois: action.payload.birthday.getMonth()+1,
                    annee: action.payload.birthday.getFullYear(),
                    //heure: action.payload.birthday.getHours(),
                    //minute: action.payload.birthday.getMinutes()
                }})
                return newItems
            }
            break
        }  
        case Types.SET_ZODIAC: {
            const newItems = Object.assign({},state,action.payload)
            return newItems
        }
        case Types.SET_ELEMENT: {
            const newItems = Object.assign({},state,action.payload)
            return newItems
        }
        case Types.SET_ANGE: {
            const newItems = Object.assign({},state,action.payload)
            return newItems
        }
        default: return state;
    }
}
