import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
    signeAstrologique,
    compareDate,
} from '../fonctions/calculs'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import { useDispatch, useSelector } from 'react-redux'
import { setName, setDate, setZodiac } from '../redux/action'

import { anges } from '../fonctions/anges'

const Formulaire2 = () => {
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(new Date());
    const { name, prenom } = useSelector(state => state)
    const { jour, mois, annee } = useSelector(state => state.birthday)


    const birthday = jour + mois + annee
    const signe = signeAstrologique(jour, mois)

    useEffect(() => {
        dispatch(setDate({ birthday: new Date() }))
        dispatch(setZodiac({ zodiac: signe }))
    }, [])

    useEffect(() => {
        dispatch(setZodiac({ zodiac: signe }))
    }, [dispatch,signe])

    const handleDateChange = (choix) => (date) => {
        if (choix === 'date')
        {
            setSelectedDate(date);
            dispatch(setDate({ birthday: date }))
        }
        else
        {
            setSelectedHour(date);
        }
    }

    useEffect(() => {
        const ange = anges.find((e) => compareDate(jour, mois, e.calendrierPhysique))
        dispatch(setZodiac({ ange: ange }))
    }, [dispatch,jour, mois, birthday])

    // Callback appelée par chaque appel dans un input
    const onChange = (label) => (event) => {
        //event.preventDefault()
        let value = event.target.value
        dispatch(setName({
            [label]: typeof (value) === 'string' ?
                value.toLowerCase()
                    .replace(/é|è|ê/g, 'e')
                    .replace(/-/g, '') :
                value
        }))
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '15px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0 10px 0' }}>
                <div className='col-6'>
                <TextField
                    value={prenom}
                    variant='outlined'
                    label='Prenom'
                    onChange={onChange('prenom')}
                    style={{marginRight:'15px', marginBottom:'10px'}}
                />
                <TextField
                    value={name}
                    variant='outlined'
                    label='Nom'
                    onChange={onChange('name')}
                    style={{ marginRight:'15px'}}
                />
                </div>
                <div className='col-6' >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk={true}
                        invalidDateMessage="Date non valide"
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Date de Naissance"
                        value={selectedDate}
                        onChange={handleDateChange('date')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        style={{width:'150px', marginRight:'15px'}}
                    />
                    <KeyboardTimePicker
                        ampm={false}
                        autoOk={true}
                        invalidDateMessage="Heure non valide"
                        margin="normal"
                        id="time-picker"
                        label="Heure de Naissance"
                        value={selectedHour}
                        onChange={handleDateChange('heure')}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        style={{width:'150px'}}
                    />
                </MuiPickersUtilsProvider>
                </div>
            </div>
            <div>
            <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                <h3 style={{ color: '#61dafb', marginRight:'15px' }}>{signe}</h3>
                
                <img
                    src = {signe && `./images/${signe}_40px.png`}
                    alt = ""
                    className="img-responsive"
                    width='25px'
                    height='25px'
                    />
                </div>
                {/*`Nombre d'exp : ${nbreExpression(name + prenom)} - Nombre actif : ${nbreActif(prenom)} - Nombre héréditaire : ${nbreHereditaire(name)} - Date: ${birthday} ${calculNaissanceTab(birthday.toString())}`*/}
            </div>

        </div>)
}

export default Formulaire2