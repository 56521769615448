import {compose, createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducer'
const sagaMiddleware = createSagaMiddleware()

const enhancers = compose( // version pour avoir les dev tools sur chrome et electorn
    applyMiddleware(sagaMiddleware),
    typeof window !== 'undefined' &&  window.__REDUX_DEVTOOLS_EXTENSION__ 
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : x => x
)
const store = createStore(reducers, enhancers)

export default store
