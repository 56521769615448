export const Types = {
    GET_NAME: 'GET_NAME',
    SET_NAME:'SET_NAME',
    SET_DATE: 'SET_DATE',
    SET_ZODIAC: 'SET_ZODIAC',
    SET_ELEMENT: 'SET_ELEMENT',
    SET_ANGE: 'SET_ANGE'
}

// function that returns an object literal 
export const getName = () => ({
    type: Types.GET_NAME
})
// key: payload will contain result of the api call
export const setName= (data) => ({
    type: Types.SET_NAME,
    payload: data 
})

export const setDate= (data) => ({
    type: Types.SET_DATE,
    payload: data
})

export const setZodiac= (data) => ({
    type: Types.SET_ZODIAC,
    payload: data
})

export const setElement= (data) => ({
    type: Types.SET_ELEMENT,
    payload: data
})

export const setAnge= (data) => ({
    type: Types.SET_ANGE,
    payload: data
})