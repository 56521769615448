import React from 'react'
import './App.css'
import Formulaire2 from './composants/Formulaire2'
import Resultat from './composants/Resultat'

function App() {
  return (
    <div className="App">
      <header className="App-header" > {/*style={{position: 'fixed',top: 0, width: '100%'}}*/}
        <h1>
          Etude numérologique et Angélique.
        </h1>
        <a
          className="App-link"
          href="https://lazuryte.fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lazuryte
        </a>
      </header>
      <div className='container'>      
        <Formulaire2/>
        <Resultat/>
      </div>
    </div>
  )
}
export default App
