import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip
} from "recharts"
import { NombreDominants } from '../fonctions/constantes'

export const TwoLevelPieChart = ({ datas }) => {
  
  const nbDominants = datas.map((e, key) => Object.assign({ nomber: key, A: e }))
  nbDominants.splice()

  return (<div style={{ display: 'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'flex-start' }}>
    <RadarChart
      cx={150}
      cy={150}
      outerRadius={150}
      width={300}
      height={400}
      data={nbDominants}
    >
      <PolarGrid />
      <Tooltip />
      <PolarAngleAxis dataKey="nomber" />
      <PolarRadiusAxis angle={30} />
      <Radar
        name="Dominants"
        dataKey="A"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.5}
      />
      {/*<Radar
        name="nombre2"
        dataKey="B"
        stroke="#82ca9d"
        fill="#82ca9d"
        fillOpacity={0.5}
      />*/}
      <Legend />
    </RadarChart>

    <ul>
      {datas.map((e, key) => {
        return (
          <li>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyItems: 'flex-start' }}>
              {`${key}: ${NombreDominants[key - 1].nom}`}
              <p>{NombreDominants[key - 1].description}</p>
            </div>
          </li>

        )
      })}
    </ul>

  </div>
  )
};
