import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const config = {
  apiKey: "AIzaSyBDjphXRLNlqvSZHPNDhE92ds1KgWF1P2U",
  authDomain: "authentification-c0cba.firebaseapp.com",
  databaseURL: "https://authentification-c0cba.firebaseio.com",
  projectId: "authentification-c0cba",
  storageBucket: "authentification-c0cba.appspot.com",
  messagingSenderId: "1059526037166",
  appId: "1:1059526037166:web:36c6bafbebc53c1d2b273f",
  measurementId: "G-7BKD8PP2BG"
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(config)
}
