export const CheminVie = [
    {
        id: 1,
        name: 'Interprétation du Chemin de Vie 1',
        resume: "L'indépendance",
        description: "C'est une route qui peut vous mener vers la réussite sociale, professionnelle ou parfois affective. Il vous faudra passer par des redémarrages à zéro. Votre rôle sera celui d'un innovateur, d'un leader ou d'un directeur. Réticent à la hiérarchie, vous devrez avoir des qualités d'organisation et de réalisation car vous n'êtes pas forcément un exécutif. Souvent seul à vous battre comme le chiffre l'indique, il ne faudra compter que sur vous-même, surmonter les obstacles et choisir les bonnes opportunités. Pour bien réaliser ce chemin de vie, apprenez l’originalité, la créativité et l’indépendance. N'oubliez pas que vous avez les qualités d' un pionnier mais vous devez vous réaliser sans compter sur les autres. Le but est d’entreprendre et de maîtriser. Excellentes opportunités pour réussir dans les finances ou la création. Développez l’affirmation de vous-même et votre pouvoir personnel mais ne vous montrez pas trop autoritaire. Vous êtes souvent surpris que les autres ne se rangent pas à vos opinions car vous n’aimez guère que l’on vous résiste. Vous êtes parfois trop dominateur.",
        description2: "Vie d'action individuelle.C'est un chemin de vie qui favorise la réussite personnelle et les réalisations individuelles. Il symbolise une vie active et, est souvent le théâtre de changements inattendus. Il est parfois difficile, mais une certaine chance domine et permet de vaincre les obstacles. Les qualités nécessaires pour bien l'assumer sont: la volonté, le courage, la confiance en soi et la persévérance.Chemin difficile pour ceux qui ont 1 comme nombre manquant. Difficulté également pour les nombres d'expression 2 et 4."
    },
    {
        id: 2,
        name: 'Interprétation du Chemin de Vie 2',
        resume: "L'association",
        description: "La route est semée de contradictions et vous aurez tendance à ne pas sortir des sentiers battus. Les relations affectives et amicales ont une place prépondérante et vous travaillez uniquement par souci de stabilité et de sécurité. Besoin de tendresse, de compréhension et d'harmonie avec l'entourage. Ce parcours est fait pour les paisibles, soumis ou dépendants des gens et des événements. Vous recherchez la collaboration et vous évitez la solitude que ce soit sur le plan sentimental ou professionnel. L'allure est un peu lente et vous n'aimez pas être bousculé. Doué pour jouer des rôles de médiateurs, de diplomate, votre sensibilité joue un grand rôle dans les relations humaines. Ce programme favorise les unions, les associations, tout ce qui se fait à deux. Particulièrement susceptible, vous avez tendance à jouer les paranos et à vous sentir incompris ! Très persuasif vous savez mettre les gens dans votre poche. Ce chemin favorise les amitiés et apporte un bonheur simple sans trop de conflits. Canalisez vos tendances à vous soumettre à votre destin et à rester passif. Taisez votre ego et apprenez à tenir compte du besoin des autres.",
        description2: "Vie de collaboration et d'harmonie avec les autres.C 'est un chemin de vie qui favorise l'association et le mariage. On y recherche l'affection et l'amitié. Il symbolise une certaine passivité et il y a parfois une tendance à vivre au gré des événements. Il comporte beaucoup de virages et la réussite vient avec le temps à moins qu'elle ne vienne de façon inattendue grâce à l'aide d'autrui. Les qualités nécessaires pour bien l'assumer sont: la diplomatie, la patience et l'équilibre.Chemin difficile pour ceux qui ont 2 comme nombre manquant. Difficulté également pour les nombres d'expression 1, 5, 9, 11 et 22."
    },
    {
        id: 3,
        name: "Interprétation du Chemin de Vie 3",
        resume: "Les relations",
        description: "La route devrait être plaisante... Les contacts et les relations avec autrui sont faciles. L'expression personnelle est favorisée et les activités sont généralement gaies et agréables. La beauté, l'esthétique, l'amitié et l'humour en font partie. Vous avez peur de la monotonie et de l'ennui. Pour le fuir, vous auriez tendance à vous investir dans trop de choses à la fois. L'activité financière est fluctuante et les imprudences doivent être évitées. Vous aimez bouger, prendre la route. Vos relations avec les autres peuvent être superficielles. Chemin des artistes, des commerçants, tout ceux qui s'expriment verbalement et qui s'ouvrent au monde extérieur. Votre joie de vivre, vos qualités intellectuelles et artistiques vous ouvrent des portes dans l'enseignement, le sport, la création artistique ou la littérature. Vous avez de l’humour et les plaisirs tiennent une grande place dans votre vie. Ce chemin favorise les contacts, les rencontres, les appuis, les voyages et le succès. Vous avez besoin de liberté et de grands espaces. Tendance à se disperser.",
        description2: "Vie de créativité et d'expression.C 'est un chemin de vie qui favorise les activités de contact et les relations avec autrui. Il symbolise une vie plaisante et sociable comportant peu d'obstacles et la possibilité de réussir rapidement (et parfois brillamment). Ceux qui font preuve de créativité, d'ingéniosité et d'allant dans les entreprises évoluent avec bonheur et succès sur ce chemin. Les qualités nécessaires sont: l'extraversion, le sens des relations et des contacts et l'ambition.Chemin difficile pour ceux qui ont 3 comme nombre manquant. Difficulté également pour le nombre d'expression 4."
    },
    {
        id: 4,
        name: "Interprétation du Chemin de Vie 4",
        resume: "La rigueur",
        description: "La route est longue et laborieuse. Seul l'effort personnel vous permettra d'avancer et on a la drôle d'impression que vous n'êtes pas sur terre pour rigoler ! La fantaisie et les plaisirs faciles ne font guère partie du programme. N'orientez pas toute votre énergie vers les biens matériels sinon vous passerez à côté de choses merveilleuses. Evitez de trop porter d'œillères et de devenir sectaire un peu comme le capricorne maître de sa vie et de ses émotions... Vous pouvez aimer l'ordre et la discipline sans pour autant faire preuve d'incompréhension ou de paranoïa excessive. Vous obtiendrez une certaine sécurité matérielle si vous cherchez à construire de solides fondations pour votre existence. N'oubliez pas que vous devrez apprendre à servir autrui, à accepter les limitations et à vivre en harmonie avec celles-ci sans vous lamenter sur votre sort. Avec un tel chemin de vie, il faudra vous montrer concret, pratique, ponctuel, capable, consciencieux, honnête et responsable. Votre vie de famille sera conventionnelle et sans grande fantaisie. Tendance à vivre en vase clos, à rester enfermé entre... quatre murs.",
        description2: "Vie de travail et de construction.C 'est un chemin de vie qui favorise le succès par le travail et les efforts réguliers. Il symbolise les entreprises stables et sérieuses et permet généralement une réussite solide, même si l'évolution se fait lentement. Il comporte peu de risques, mais manque d'une certaines fantaisie et rien n'est acquis par la facilité. Les qualités nécessaires pour bien l'assumer sont: l'amour du travail bien fait, la régularité, la rigueur et la persévérance.Chemin difficile pour ceux qui ont 4 comme nombre manquant. Difficulté également pour les nombres d'expression 1, 3, 5, 8 et 11."
    },
    {
        id: 5,
        name: "Interprétation du Chemin de Vie 5",
        resume: "La liberté",
        description: "La route est marquée par des changements et des événements imprévus. Elle doit s'adapter aux virages du parcours. Vous vous intéressez à tout. Vous avez mille projets que vous ne menez pas forcément à terme car vous vous lassez vite des choses comme des gens. Vous vivez au présent, à l'instant T, et advienne que pourra, vous trouvez toujours une planche de salut pour vous en sortir. L'existence est fertile en rebondissements, en audace et en agitation cérébrale. Le chemin est instable, frisant l'immoralité. Vous apprenez beaucoup par les voyages et les multiples rencontres que vous faites car vous êtes curieux de tout mais attention à la dispersion. Tenté par les abus, vous devez apprendre à vous contrôler et à respecter la liberté des autres. Dans de telles conditions, vous risquez de changer souvent de partenaire, de foyer, d’entourage, de travail, voire de pays, d'autant que vous êtes capable de vous adapter à toute situation. Apprenez à développer la maîtrise des sens et l’expression de vous-même.",
        description2: "Vie de mobilité et de changements.C 'est un chemin de vie qui favorise les changements dans tous les domaines de l'existence. Sous la vibration 5, la vie connaît des transformations fréquentes. Le chemin 5 symbolise les voyages, les activités physiques et la liberté personnelle; parfois l'aventure. Il promet une vie passionnante et riche d'événements imprévus, mais il comporte aussi des risques, ainsi que des menaces d'accidents. Les qualités nécessaires pour bien l'assumer sont: la souplesse, l'adaptation ainsi que l'audace et la santé (morale et physique).Chemin difficile pour ceux qui ont 5 comme nombre manquant. Difficulté également pour les nombres d'expression 2, 4 et 6."
    },
    {
        id: 6,
        name: "Interprétation du Chemin de Vie 6",
        resume: "Les Responsabilités",
        description: "La route peut être bordée de fleurs si vous savez faire les bons choix... car il faudra prendre les bonnes décisions face à ceux-ci. Chez vous, tout passe par l'affectif. Le couple et le foyer sont les bases essentielles à votre équilibre et à votre réussite. Les goûts artistiques et esthétiques sont sûrs et une activité créative ou artistique vous est fortement conseillée. Le sens des responsabilités est présent dans un tel programme. Influençable, vous ne supportez pas de vivre seul et pourriez mal vous entourer du moment que vous avez de la compagnie. Vous fuyez les conflits et les ruptures vous affligent. Vous faites souffrir sans le vouloir car vous ne savez pas 'partir'. Les sciences divinatoires vous attirent. Sur le plan sentimental, vous avez besoin d’être sur la même fréquence émotionnelle que l’autre. Vous devez apprendre le sens des responsabilités pour votre famille et la compréhension des besoins d’autrui. Sachez rendre service et vous serez ' payé en retour' . Difficulté à faire des choix.",
        description2: "Vie de responsabilité et d'harmonie sentimentale.Ce chemin de vie implique des choix à faire et il n'est pas toujours facile de s'engager dans la bonne direction. Si la voie choisie est positive et ambitieuse, l'ascension est rapide et mène loin et haut. Sinon, il y a des hésitations et des engagements dans plusieurs directions dont les conséquences sont rarement bénéfiques.Le chemin 6 symbolise les responsabilités qui peuvent aussi se transformer en fardeaux ou en épreuves. C'est également le chemin de l'amour et du mariage, car le 6 est foyer et famille. Les qualités nécessaires pour bien l'assumer sont: la volonté (car le libre arbitre est prédominant sur ce chemin), l'esprit de conciliation et d'adaptation. Il y a une tendance naturelle au perfectionnisme qui risque de poser des problèmes.Chemin difficile pour ceux qui ont 6 comme nombre manquant. Difficulté également pour le nombre d'expression 5."
    },
    {
        id: 7,
        name: "Interprétation du Chemin de Vie 7",
        resume: "La Vie intérieure",
        description: "C'est la route du philosophe ou du mystique... Solitaire, vous avez besoin de calme pour vous ressourcer. Exigeant et perfectionniste, vous appréciez la nature, la mer, l'écologie, la musique qui favorisent l'analyse et la réflexion. Après 30 ans, vous risquez de vous ouvrir à la spiritualité, la méditation, une religion ou une philosophie étrangère. Vous délaisserez le matériel pour travailler sur une évolution de l'esprit plus spirituelle car votre chemin est paisible, mystérieux et retiré du monde matérialiste. C’est un programme de vie où vous devez développer votre esprit, car il parlera avec sagesse et vous devrez avoir confiance en vous, en la vie et en l'Univers. Sur le plan affectif, vous vouez en l’amour, un véritable accomplissement. Sachez contempler et juger hors des apparences. Sur ce chemin, les amitiés seront une source de satisfactions. Vous aimez le mystère. Des remises en question seront à l'ordre du temps.",
        description2: "Vie intérieure et indépendance.C 'est un chemin de vie qui favorise les travaux de l'esprit, la vie intérieure ou spirituelle et tout ce qui a un rapport avec l'analyse ou la recherche. Il symbolise le goût de l'indépendance et parfois de la solitude. Il caractérise souvent une destinée originale, avec une réussite désintéressée. Les amitiés sont source de grandes satisfactions, mais le mariage n'est pas toujours facile. Les qualités nécessaires pour bien l'assumer sont: l'intérêt pour autrui, la réflexion et le désintéressement pour ce qui concerne les aspects matériels de l'existence.Chemin difficile pour ceux qui ont 7 comme nombre manquant (retard et délais dans les réalisations). Difficulté également pour les nombres d'expression: 8, 11 et 22."
    },
    {
        id: 8,
        name: "Interprétation du Chemin de Vie 8",
        resume: "Le Matériel",
        description: "C'est une route difficile où tout est permis, dans le sens ou tout peut vous arriver... Réussite ou échec, richesse ou pauvreté, tout ou rien. Ne soyez pas aussi autoritaire, freinez votre orgueil et votre intolérance. Très exigeant pour vous-même et pour les autres, vos réactions peuvent être vives et impulsives. C'est le chemin de la réussite matérielle, des gains financiers, du Pouvoir. Vous inspirez confiance car vos jugements sont fondés et réfléchis. Vous ne supportez pas les bavardages, l’important est d’aller à l’essentiel. Jaloux, vous luttez cependant contre vos rapports trop possessifs ou trop exclusifs. En amour, vous êtes vif, joyeux et habile dans vos paroles mais vous pouvez rompre un contrat amoureux, sans appel possible. Vous savez tirer une leçon de chaque expérience. Il vous faudra apprendre à vous maîtriser et à franchir les obstacles (accidents, pertes, dépossession). Attention, c’est un chemin risqué qui ne laisse rien passer. Plus que tous les autres, vous êtes sous la loi du karma : vous récoltez toujours ce que vous avez semé.",
        description2: "Vie de réalisations ambitieuses et d'acquisitions matérielles.C 'est un chemin de vie qui favorise les ambitions et les réalisations d'envergure. Il symbolise le pouvoir, l'argent et la matérialité. Le chemin 8 est difficile car il comporte des risques et des épreuves mais il peut mener à une réussite extraordinaire. Il n'est pas exempt d'accidents et de problèmes de santé. Les qualités nécessaires pour bien l'assumer sont: le courage, l'endurance et le sens de l'équilibre.Chemin difficile pour ceux qui ont 8 comme nombre manquant. Difficultés également pour les nombres d'expression 4 et 7. Les 2 et les 9 n'y sont pa toujours à l'aise."
    },
    {
        id: 9,
        name: "Interprétation du Chemin de Vie 9",
        resume: "L'idéal collectif",
        description: "C'est le chemin de la passion et de la compassion qui part vers le lointain, l'étranger, les grands horizons, les voyages au long cours... en avion ou dans la tête car ce programme permet de voyager, en réalité ou par le rêve. Vous devrez faire preuve de souplesse, d'adaptation et de grande tolérance. Vous aurez à défendre la veuve et l'orphelin, à jouer les médiateurs et les arbitres. Des rencontres multiples et des voyages à l'étranger vous feront avancer sur le plan spirituel. D'une extrême sensibilité et d'une générosité désintéressée vous êtes un artiste et un excellent confident mais vous êtes trop nerveux. Si votre enfance fut difficile, vous risquez de traîner toute votre vie une plaie non cicatrisée. Sachez que vous donnerez aux autres, plus que vous ne recevrez. Vous êtes là pour montrer la voie du Nouvel Age, de l’altruisme, de la compréhension et de la sagesse. C'est pour cela que vous devrez vous ouvrir à la spiritualité et développer une pensée illimitée.",
        description2: "Vie d'évasion ou d'idéal.C 'est un chemin de vie qui favorise les voyages: ceux de l'esprit ou de l'âme, et ceux que l'on peut entreprendre sur la planète. Il promet beaucoup de rencontres et d'expériences variées. Il symbolise la recherche d'un idéal ou la réalisation d'une vocation. Il comporte beaucoup d'émotions et la réussite se manifeste souvent de façon imprévue, non sans écueils et sans obstacles. Les qualités nécessaires pour bien l'assumer sont: la compréhension et le dévouement, la sensibilité et un esprit ouvert, ainsi que le courage. Parfois il faut vaincre une tendance aux rêves ou aux illusions.Chemin difficile pour ceux qui ont 9 comme nombre manquant. Difficultés également pour les nombres d'expressions 2 et 8."
    },
    {
        id: 11,
        name: "Interprétation du Chemin de Vie 11",
        resume: " La révélation ",
        description: "Rares sont les personnes qui peuvent assumer un tel chemin et retombent dans le Chemin de Vie 2. L'idéal est très élevé, illuminé de visions et de révélations. Vous serez particulièrement doué dans les domaines de la création et de l’ésotérisme et toutes vos connaissances doivent servir à autrui. C’est le chemin de l’inspiration : vos mots clés sont altruisme et amour du prochain. Si vous savez canaliser votre tension nerveuse, importante dans un tel programme de vie, vous pouvez atteindre un très haut niveau de conscience et développer des dons psychiques. Il vous faudra apprendre la patience et savoir prendre des décisions rapides dans n’importe quelle situation. C'est le chemin des évangélistes, médiums, astrologues, numérologues, clairvoyants, inventeurs ou écrivains, mais vous êtes avant tout un philosophe du Nouvel Age et un guide pour les changements à venir. Il est bien entendu qu'une réussite matérielle n'est guère prévue dans un tel contexte !"
    },
    {
        id: 22,
        name: "Interprétation du Chemin de Vie 22",
        resume: " La construction universelle ",
        description: "Rares également sont ceux qui peuvent assumer un tel chemin... qui se réduit rapidement au 4. Ici, on travaille pour l'humanité, on construit des pyramides ou des cathédrales. La collectivité et l'humanité sont concernées mais sur le plan concret. C'est le chemin des réussites extraordinaires ou celui de la folie. Vous devez voir loin dans le futur et aucun doute ne doit vous assaillir. La vie privée devient quasi-inexistante, avalée par la vie sociale. Aucun intérêt personnel ni aucune attirance pour le matériel n'est toléré dans ce programme. Une grande tension nerveuse accompagne le porteur du 22 qui le rend difficile à supporter pour son entourage. Sur ce chemin, il vous faudra vous exprimer dans la construction de grandes choses pour l’humanité, que ce soit dans le domaine de l’architecture, gouvernemental ou dans toute société internationale où de grandes affaires sont traitées. Ce potentiel est excessivement imaginatif et créateur ce qui peut, éventuellement, vous conduire vers des carrières artistiques. Vous devez apprendre à manier l’argent au profit des pays ou des grandes collectivités. Attention aux sautes d'humeur."
    }
]

export const anneePersonnelle = [
    {
        id: 1,
        description: "Représente une période de courage, de force et d'autorité. Il est conseillé de se mettre en avant et de se montrer confiant en toute choses.Personne ne viendra faire les tâches à votre place, vous devrez prendre par vous même les choses en mains. C'est aussi une année favorable pour créer de nouveaux projets, prendre de nouvelles décisions , semer la graine pour en récolter les fruits dans l'année 8 et faire le bilan dans l'année 9."
    },
    {
        id: 2,
        description: "Représente une période de repli, de sensibilité et de fragilité sentimentale. Il est conseillé de ne pas imposer ses idées à tout prix et faire davantage confiance à votre entourage.De nouvelles rencontres peuvent survenir, vous devrez vous rendre serviable par l'écoute d'autrui. C'est une année favorable pour créer de nouveaux contacts, mais attention à la dualité. , Il faut mettre son égo de coté et améliorer votre image pour bien préparer l'année 3."
    },
    {
        id: 3,
        description: "Représente une période d'expansion vers l'entourage. Il est possible de s'extérioriser davantage et de devenir l'acteur principal face aux autres.Invitations, flirt, festivités et humour sont au rendez-vous. C'est une année favorable pour faire du sport et créer ou consolider de nouveaux contacts. Une très bonne année en perspective. ."
    },
    {
        id: 4,
        description: "Représente une période de ralentissement, de renfermement et de consolidation des acquis. C'est un cycle qui favorise les travaux en tout genres et qui empêche bien souvent d'avancer.Il est conseillé de faire le tri et de mettre de l'ordre dans ses affaires. La patience et la rigueur sont recommandées. ."
    },
    {
        id: 5,
        description: "Représente une période de changement, de mobilité. Il est question dans ce cycle d'avoir des opportunités tel qu'un déménagement, un voyage, un changement de travail, un achat de véhicule ou encore un changement de partenaire..La curiosité et la tentation sont en première ligne. Il est conseillé de ne pas faire de projet à long terme car au changement succède le changement. ."
    },
    {
        id: 6,
        description: "Représente une période de serviabilité sur votre entourage. La famille, les voisins sont au premier plan. Votre coté artistique et vos conseils risquent d'être sollicités.Possibilité cette année d'être influencé par l'esthétique, tout ce qui est de forme ronde ou encore les évènements musicaux. Vos responsabilités sont souvent en rapport avec l'aide médicale, l'aide envers le voisinage ou familiale. ."
    },
    {
        id: 7,
        description: "Représente une période d'introspection, de réflexion et d'analyse. C'est une année où le subconscient est plus actif que le conscient.On se reconstruit de l'intérieur, on s'assagit. On donne moins d'importance aux apects matériels. L'intuition personnelle se développe et peut parfois nous donner un esprit d'inventeur ou de psychologue averti... ."
    },
    {
        id: 8,
        description: "Représente une période de réveille, de réalité matérielle. C'est une année très terre à terre où l'argent et le pouvoir dominent sur le reste.On y trouve dans ce cycle beaucoup de problèmes matériel, pannes en tout genre, fuites d'eau, drogue, violence, incivilité... Une année où la justice joue un rôle primordiale. Notre détermination est puissante, idéal pour arrêter de fumer...."
    },
    {
        id: 9,
        description: "Représente le bilan de nos actes, sont-ils présentables face au monde?. C'est une année où l'on doit rendre des comptes au créateur sur ce que nous avons semé ces 8 dernières années.La sensibilité est forte et on a l'impression de parler avec le ciel. Période Idéale pour les voyages et les représentations à travers le monde. Artistes favorisés. ."
    }

]

export const Expression = [
    {
        id:1,
        description:"Personnalité: Volonté, autorité. Confiance en soi. Ambition. Tendance à l'égoïsme et à la domination.Amour: Dans la vie à deux, le 1 affirme son autorité. L'autre doit faire preuve de docilité et de fidélité. Le 1 est généreux et passionné; mais son attitude externe peut être froide et distante, voire fière.Travail: Le 1 a l'âme d'un chef. Il commande et dirige. Il accepte difficilement les erreurs des subalternes. Le 1 est exigeant dans ses associations.Santé: Les points faibles sont les yeux, le coeur, la circulation sanguine."
    },
    {
        id:2,
        description:"Personnalité: Equilibre, diplomatie, sensibilité, imagination. Tendance à la passivité, à la paresse et à la soumission.Amour: Le 2 a un immense besoin d'amour. Il s'unit ou se marie même s'il n'est pas toujours sûr de ses sentiments. Le 2 est doux et rêveur.Travail: Le 2 est un collaborateur. Il lui faut une ambiance équilibrée et sereine. Il aime les contacts et est doué pour les négociations. Il est meilleur dans l'exécution que dans la direction, sauf s'il exerce une activité indépendante en rapport avec la création ou le commerce.Santé: Les points faibles sont les glandes et l'estomac."
    },
    {
        id:3,
        description:"Personnalité: Créativité, sociabilité, générosité, franchise. Tendance à la colère, à l'orgueil et à la dispersion.Amour: Le 3 est gai et jovial. Il est capable d'un amour profond et passionné, mais il aime aussi les plaisir de l'amour. Il met du temps à s'engager dans une union sérieuse et il recherche alors un mariage brillant ou intéressant.Travail: Le 3 rayonne et communique. Le mouvement et l'activité lui sont indispensables (beaucoup de sportifs sont 3). Il a le goût de l'organisation et des relations, avec l'objectif de réussir matériellement.Santé: Les points faibles du 3 sont le foie et la circulation artérielle."
    },
    {
        id:4,
        description:"Personnalité: Patience, stabilité, persévérance, sens de l'organisation et de la précision. Tendance à la routine, à la lenteur, parfois à l'avarice.Amour: Le 4 est capable de sentiments profonds et stables mais il est peu démonstratif. La discrétion, voire la réserve sont caractéristiques chez lui. Le 4 est fidèle et a des principes.Travail: Le 4 est un travailleur né. On lui fait confiance car il a des qualités d'ordre et de méthode. L'ascension est lente mais régulière. Il doit sa réussite à ses efforts assidus.Santé: Les points faibles du 4 son les os, les dents et les articulations."
    },
    {
        id:5,
        description:"Personnalité: Qualités d 'adaptation, souplesse, vivacité, mobilité. Tendance à l'impulsivité et à l'instabilité.Amour: Le 5 n'est pas à proprement parler un sentimental. Il plaît et séduit par son charme, son esprit, ses belles paroles et sa délicatesse. Son goût du changement n'est pas toujours compatible avec les unions durables, mais il arrive qu'il s'unisse définitivement pour la bonne cause.Travail: Le 5 s'intéresse à des domaines variés et il n'est pas rare de le voir changer de profession. Les voyages lui sont favorables. Il a besoin de bouger et ses qualités d'adaptation ainsi que sa facilité de contact le font souvent réussir.Santé: Les points faibles du 5 sont le système nerveux, les poumons et la gorge."
    },
    {
        id:6,
        description:"Personnalité: Charme, esprit conciliant, sens de la beauté, sentimentalité. Tendance à la facilité, à l'indécision, à une sensualité exacerbée.Amour: Le 6 se soucie de plaire et recherche le bonheur dans les plaisirs et l'amour. L'équilibre général dépend souvent de l'équilibre sentimental. Le 6 quand il s'unit pour toujours s'occupe de son foyer et a l'esprit très famille.Travail: Le 6 n'est pas débordant d'activité et manque un peu de rigueur. Il est très doué pour les transactions et les accords grâce à un esprit conciliant. Il a le sens des responsabilités et les assume. Certains 6 font de belles carrières artistiques.Santé: Les points faible du 6 sont la gorge, la voix et les organes sexuels (surtout les femmes)"
    },
    {
        id:7,
        description:"Personnalité: Sens de l'analyse, goût de l'étude, culte de l'amitié, intuition. Tendance au pessimisme, à l'angoisse, à l'entêtement.Amour: Le 7 a des sentiments indépendants et ne s'embarrasse pas de détails. Les amours et les ruptures se déroulent souvent d'une façon inattendue. L'union durable n'est pas facilitée, mais possible avec un partenaire qui partage les mêmes intérêts et qui respecte son indépendance. Le 7 exige beaucoup de l'autre.Travail: Le 7 recherche les activités indépendantes et évite la monotonie ou la routine. Il supporte mal l'autorité d'un autre. Il fait preuve d'originalité et réussit souvent en dehors des sentiers battus.Santé: Les points faibles du 7 sont les nerfs et le psychisme."
    },
    {
        id:8,
        description:"Personnalité: Energie, courage, esprit entreprenant. Tendance à la dureté, à l'agressivité et à la rancune.Amour: Le 8 est impétueux et la patience n'étant pas son fort, il lui faut des conquêtes rapides. Il aime avec passion, voire avec violence, mais ses sentiments sont francs. Dans l'union, le 8 domine et a tendance à se montrer jaloux. Avec le 8, on sait ou on va...Travail: Le 8 a une grande capacité de travail et il a besoin d'une activité qui lui permette de dépenser son énergie vitale. Il a le sens du concret et sait prendre des décisions rapides. Le 8 réussit souvent par à-coups et rapidement.Santé: Les points faible du 8 sont la tête et les organes sexuels (surtout chez les hommes). Risques de blessures et d'opérations."
    },
    {
        id:9,
        description:"Personnalité: Altruisme, dévouement, émotivité, caractère passionné, idéalisme. Tendance au désordre, à la crédulité, à la contradiction.Amour: Le 9 a tendance à demander constamment des preuves de l'amour qu'on lui porte. Il n'est pas très romantique, mais fait preuve d'affection. Il a des qualités certaines de loyauté et de fidélité. Certains 9 ne trouvant pas leur équilibre se laissent entraîner dans des aventures qui ternissent leur image.Travail: Le sens du dévouement et une grande capacité d'assimilation sont des atouts importants qui aident la réussite du 9. Le 9 ne peut travailler efficacement que s'il y croit. Il manque un peu de sens pratique et se laisse facilement influencer.Santé: Les points faibles du 9 sont le psychisme et le système lymphatique."
    },
    {
        id:11,
        description:"Personnalité: Force morale et physique, inspiration, ambition, ascendant sur autrui. Tendance à la nervosité, à l'impatience, voire à la brutalité.Amour: Le 11 a une force de caractère qui doit ^étre acceptée par l'autre. Sa personnalité est très attirante et dégage même un certain magnétisme. Le 11 domine et accepte mal la contradiction.Travail: Le 11 a une vue large de la vie et se réalise généralement avec succès dans le domaine qui l'intéresse. Il recherche les responsabilités et a le sens du commandement.Santé: Le point faible du 11 est le cerveau. Tendance au surmenage et à la dépression."
    },
    {
        id:22,
        description:"Personnalité: Maîtrise, esprit constructif, inspiration, puissance de travail et de réalisation. Tendance à l'extravagance et à l'aveuglement.Amour: Le 22 est très préoccupé par ses projets et ses ambitions et sa vie sentimentale propre est nettement secondaire. Le grand amour est possible à condition que l'autre épouse également ses passions et ses intérêts. Le 22 a une forte personnalité qui doit être comprise et acceptée.Travail: Le 22 a une grande inspiration créatrice qui se transforme en réalisation concrète. Il crée et construit non seulement pour lui, mais aussi pour les autres. La réussite, voire la renommée s'inscrit grâce à une puissance et une capacité de travail exceptionnelles.Santé: Le point faible du 22 est la charpente osseuse. Par ailleurs, risque de troubles nerveux et psychiques."
    }

]

export const Hereditaire = [
    {
        id:1,
        description: "Courage, assurance."
    },
    {
        id:2,
        description: "Equilibre, sociabilité."
    },
    {
        id:3,
        description: "Créativité, adaptabilité."
    },
    {
        id:4,
        description: "Méthode, ordre."
    },
    {
        id:5,
        description: "Combativité, ardeur."
    },
    {
        id:6,
        description: "Justice, générosité."
    },
    {
        id:7,
        description: "Raison, sagesse."
    },
    {
        id:8,
        description: "Autorité, détermination."
    },
    {
        id:9,
        description: "Dignité, fierté."
    },
    {
        id:11,
        description: "Inspiration, force."
    },
    {
        id:22,
        description: "Construction, élévation."
    }

]

export const Actif = [
    {
        id:1,
        description: "Invention, nervosité, opportunisme."
    },
    {
        id:2,
        description: "Contacts humains, sensibilité, calme apparent."
    },
    {
        id:3,
        description: "Originalité, intelligence, habileté."
    },
    {
        id:4,
        description: "Organisation, travail, ordre."
    },
    {
        id:5,
        description: "Mouvement, curiosité, dynamisme."
    },
    {
        id:6,
        description: "Bonheur, générosité, ironie"
    },
    {
        id:7,
        description: "Mystère, observation, mélancolie."
    },
    {
        id:8,
        description: "Entêtement, action, impulsivité."
    },
    {
        id:9,
        description: "Altruisme, émotivité, sensualité."
    },
    {
        id:11,
        description: "Force, maîtrise, nervosité."
    },
    {
        id:22,
        description: "Idéal, aspirations élevées, réalisation."
    },
]

export const Intime = [
    {
        id:1,
        description: "Désir de réussir et de se réaliser par soi-même"
    },
    {
        id:2,
        description: "Désir de s'associer et de s'unir (amour, mariage)"
    },
    {
        id:3,
        description: "Désir de s'exprimer et recherche des contacts."
    },
    {
        id:4,
        description: "Désir de vivre de façon constructive. Travail et stabilité."
    },
    {
        id:5,
        description: "Désir de vivre une existence variée: changements et liberté. Voyages."
    },
    {
        id:6,
        description: "Désir d'harmonie et d'équilibre sur le plan sentimental. Intérêt artistiques."
    },
    {
        id:7,
        description: "Désir d'indépendance et d'action individuelle. Vie intérieure. Réserve."
    },
    {
        id:8,
        description: "Désir de se réaliser concrètement. Volonté d'acquérir (argent, réussite, affaires)"
    },
    {
        id:9,
        description: "Désir de réaliser un idéal ou une vocation. Recherche de l'aventure. Intérêt pour l'humanité."
    },
    {
        id:11,
        description: "Désir de s'imposer en réalisant ses ambitions ou un idéal. Inspiration."
    },
    {
        id:12,
        description: "Désir d'élévation et aspirations universelles. Travail ambitieux."
    },

]

export const Realisation= [
    {
        id:1,
        description: "Initiatives et action personnelles. Veut briller et diriger."
    },
    {
        id:2,
        description: "Travail en association ou en équipe. Collaboration. Relations et amitié."
    },
    {
        id:3,
        description: "Vie de contacts et d'échanges. Ouverture vers autrui. Réussite sociale"
    },
    {
        id:4,
        description: "Travail constructif et patient. Réussite progressive grâce aux efforts."
    },
    {
        id:5,
        description: "Changements, mutations. Mobilité et voyages. Rencontres très variées."
    },
    {
        id:6,
        description: "Choix à faire qui ne sont pas toujours faciles. Vie sociale importante."
    },
    {
        id:7,
        description: "Initiatives originales (invention et imagination). Réussite après luttes."
    },
    {
        id:8,
        description: "Attitude franche et directe dans les rapports sociaux. Equilibre à maintenir."
    },
    {
        id:9,
        description: "Dévouement et sensibilité dans les rapports avec autrui. Idéal ou vocation."
    },
    {
        id:11,
        description: "Ambitions. Activité intense. Se soumet difficilement. Autorité."
    },
    {
        id:22,
        description: "Réalisation concrète de grands projets. Réussite sociale. Grande créativité."
    },

]

export const NombreDominants = [
    {
        id:1,
        nom:'MAITRISE',
        description:'Capacité de se réaliser par soi-même.'
    },
    {
        id:2,
        nom:'ASSOCIATION',
        description:'Capacité de collaboration et d\'union.'
    },
    {
        id:3,
        nom:'SOCIABILITE',
        description:' Capacité de s\'exprimer et goût du contact.'
    },
    {
        id:4,
        nom:'REALISATION',
        description:'Capacité de travailler et de construire concrètement.'
    },
    {
        id:5,
        nom:'MOBILITE',
        description:'Capacité de changer et de bouger.'
    },
    {
        id:6,
        nom:'HARMONIE',
        description:'Capacité d\'adaptation et de conciliation. (Amour - Famille - Devoir). Dominant = Cherche l\'harmonie et l\'accord. Sens de la famille. Amour et amitié.'
    },
    {
        id:7,
        nom:'VIE INTERIEURE',
        description:'Capacité de réflexion et d\'analyse. La foi et l\'engagement des idées. Dominant = Qualité d\'invention et de recherche. Parfois engagement dans un idéal.'
    },
    {
        id:8,
        nom:'EXPANSION MATERIELLE',
        description:'Capacité de s\'assumer sur le plan matériel, d\'attirer la réussite et le pouvoir. Dominant = Recherche de la réussite à tous les niveaux. Une certaine chance matérielle. Ce qui les dessert est la volonté de réussite et le fait de toujours vouloir augmenter. Besoin de reconnaissance de gloire de pouvoir. Besoin d\'être plus que ce qui sont.'
    },
    {
        id:9,
        nom:'ALTRUISME',
        description:'Capacité de se dévouer et de s\'intéresser à autrui ou aux problèmes humains. '
    }
]

export const NombreManquants = [
    {
        id:1,
        nom:'MAITRISE',
        description:'Manquant = Insuffisance d\'assurance et de confiance en soi.'
    },
    {
        id:2,
        nom:'ASSOCIATION',
        description:'Manquant = Association difficile.'
    },
    {
        id:3,
        nom:'SOCIABILITE',
        description:'Manquant = Manque de souplesse et d\'adaptation.'
    },
    {
        id:4,
        nom:'REALISATION',
        description:'Manquant = Difficulté à organiser, et manque de stabilité.'
    },
    {
        id:5,
        nom:'MOBILITE',
        description:'Manquant = N\'aime pas le changement. Sédentaire.'
    },
    {
        id:6,
        nom:'HARMONIE',
        description:'Manquant = Mariage difficile. Esprit peu conciliant. Evite les obligations.'
    },
    {
        id:7,
        nom:'VIE INTERIEURE',
        description:'Manquant = Manque de compréhension. Nécessité de s\'ouvrir au niveau de l\'esprit.'
    },
    {
        id:8,
        nom:'EXPANSION MATERIELLE',
        description:'Manquant = Difficulté à maintenir l\'équilibre sur le plan matériel. (ou trop, ou trop peu...).'
    },
    {
        id:9,
        nom:'ALTRUISME',
        description:'Manquant = Manque d\'intérêt pour les autres.'
    }
]