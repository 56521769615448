
import React from 'react'
import { useSelector } from 'react-redux'
import {  TwoLevelPieChart } from './TwoLevelPieChart'
import { Box, Paper, Typography } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import {
    calcul1a9mot as nbreHereditaire,
    calcul1a9mot as nbreActif,
    calcul1a9mot as nbreExpression,
    calculNaissanceTab,
    reduce1a9ou11ou22ou33ou44,
    calculVoyelle,
    calculConsonnes,
    calculNbDominants
} from '../fonctions/calculs'

import { Actif, Expression, Hereditaire, CheminVie, Intime, Realisation } from '../fonctions/constantes'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other} >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 380,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    p:{
        textAlign:'justify'
    }
}))

const Resultat = () => {
    const { name, prenom, ange } = useSelector(state => state)
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const { jour, mois, annee } = useSelector(state => state.birthday)

    const sommeDate = jour + mois + annee

    const nbExpression = nbreExpression(name + prenom)
    const actif = nbreActif(prenom)
    const hereditaire = nbreHereditaire(name)
    const cheminVie = reduce1a9ou11ou22ou33ou44(calculNaissanceTab(sommeDate.toString()))
    const realisations = calculConsonnes(name + prenom)
    const intime = calculVoyelle(name + prenom)

    const res = (nb, tab) => tab.find(item => nb > 0 ? item.id === nb : null)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div style={{ display: 'flex', padding: '20px 0 10px 0' }}>
            <Paper elevation={3} style={{ width: '100%', height: '100%', padding: '15px', overflow: 'scroll' }}>
                <h1>Résultats de l'analyse</h1>
                <p>CONNAIS-TOI TOI-MÊME ET TU CONNAÎTRAS L'UNIVERS</p>
                <p className={classes.p}>
                Lorsque les rêves deviennent aussi réels que le jour,
                Lorsque ton Esprit est en quête d’un sens à son existence,
                à ses amours et à son travail,
                Lorsque tu vois au-delà des images et que tu entends au-delà des mots,
                Lorsque tu te sens à fleur de peau et que tes états d’âme sont devenus incontrôlables,
                Lorsque ton corps a mal et que tu ne sais pas pourquoi,
                Lorsque tu te demandes : « Pourquoi vivre, si je dois mourir? »
                Lorsque, face aux inégalités sociales et aux misères de ce monde,
                tu te demandes si Dieu existe…
C’est que tu es en initiation. C’est que tu es prêt à recevoir la Connaissance.</p>
                <div className={classes.root} style={{ padding: '20px 0 10px 0' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.tabs}
                        style={{ width: '20%' }}
                    >
                        <Tab label="Chemin de Vie" {...a11yProps(0)} />
                        <Tab label="Nombre héréditaire" {...a11yProps(1)} />
                        <Tab label="Nombre d'expression" {...a11yProps(2)} />
                        <Tab label="Potentiel actif" {...a11yProps(3)} />
                        <Tab label="Nombre intime" {...a11yProps(4)} />
                        <Tab label="Nombre de réalisations" {...a11yProps(5)} />
                        <Tab label="Nombre dominants/manquants" {...a11yProps(6)} />
                        <Tab label="Ton Ange" {...a11yProps(7)} />
                    </Tabs>
                    <TabPanel value={value} index={0} style={{ width: '80%' }}>
                        <h2>{`Chemin de Vie ${cheminVie}: ${cheminVie > 0 ? res(cheminVie, CheminVie).resume : null} `}</h2>
                        <p className={classes.p}>La date de naissance indique le tracé de l'existence, autrement dit le "parcours du combattant" avec ses lignes droites, ses virages, ses carrefours, ses obstacles plus ou moins difficiles à franchir. L'essentiel est de savoir quel type de parcours nous devons suivre et à quels genres d'obstacles nous devons nous attendre :</p>
                        {/*<p className={classes.p}>{cheminVie > 0 ? res(cheminVie, CheminVie).description : null} </p>*/}
                        {cheminVie > 0 ? res(cheminVie, CheminVie).description.split('.').map(i=> {return (<p className={classes.p}>{i}</p>)}) : null}
                        <p className={classes.p}>{cheminVie > 0 ? res(cheminVie, CheminVie).description2 : null} </p>
                    </TabPanel>
                    <TabPanel value={value} index={1} style={{ width: '80%' }}>
                        <h2>{`Nombre héréditaire ${hereditaire}`}</h2>
                        <p className={classes.p}>Ce nombre influence également l'expression, mais moins activement que le prénom. Pour bien interpréter l'expression du sujet, il faut tenir compte du nombre actif et du nombre héréditaire :</p>
                        {hereditaire > 0 ? res(hereditaire, Hereditaire).description.split(',').map(i => {return (<p className={classes.p}>{i}</p>)}) : null}
                    </TabPanel>
                    <TabPanel value={value} index={2} style={{ width: '80%', overflowY: 'scroll' }}>
                        <h2>{`Nombre d'expression ${nbExpression}`}</h2>
                        <p className={classes.p}>Ce nombre décrit les traits de caractère, les qualités et faiblesses du sujet. De plus, nous savons comment ce dernier s'exprime dans les différents domaines de la vie (amour, travail, amitié...) Enfin, le nombre d'expression a un rapport avec la santé physique :</p>
                        {nbExpression > 0 ? res(nbExpression, Expression).description.split('.').map(i => {return (<p className={classes.p}>{i}</p>)}) : null}

                    </TabPanel>
                    <TabPanel value={value} index={3} style={{ width: '80%' }}>
                        <h2>{`Nombre actif ${actif}`}</h2>
                        <p className={classes.p}>Ce nombre influence le nombre d'expression. Il confirme des qualités ou atténue des tendances en rapport avec l'expression : </p>
                        {actif > 0 ? res(actif, Actif).description.split(',').map(i => {return (<p className={classes.p}>{i}</p>)}) : null}
                    </TabPanel>
                    <TabPanel value={value} index={4} style={{ width: '80%' }}>
                        <h2>{`Nombre intime: ${intime}`}</h2>
                        <p className={classes.p}>Ce nombre nous renseigne sur les aspirations et les désirs profonds du sujet :</p>
                        {intime > 0 ? res(intime, Intime).description.split('.').map(i => {return (<p className={classes.p}>{i}</p>)}) : null}
                    </TabPanel>
                    <TabPanel value={value} index={5} style={{ width: '80%' }}>
                        <h2>{`Nombre de réalisations: ${realisations}`}</h2>
                        <p className={classes.p}>Ce nombre indique la façon dont une personne se réalise sur le plan de la vie concrète, sur "le terrain" (vie sociale, professionnel et matérielle) :</p>
                        {realisations > 0 ? res(realisations, Realisation).description.split('.').map(i => {return (<p className={classes.p}>{i}</p>)}) : null}
                    </TabPanel>
                    <TabPanel value={value} index={6} style={{ width: '80%' }}>
                        <h2>Nombres dominants</h2>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TwoLevelPieChart datas={calculNbDominants(name + prenom)}
                                style={{ display: "flex", justifyContent: "flex-start" }}
                            />
                        </div>
                        {/* 
                            <h2>Nombres manquants</h2>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TwoLevelPieChart datas={calculNbDominants(name + prenom)}
                                style={{ display: "flex", justifyContent: "flex-start" }}
                            />
                        </div>
                        */}
                        
                    </TabPanel>
                    <TabPanel value={value} index={7} style={{ width: '80%' }}>
                        <h2>Ton Ange: {ange && ange.nom} </h2>
                        <p className={classes.p}>À sa naissance, l’être humain reçoit trois Anges Gardiens. Les qualités et les distorsions de ces derniers nous indiquent notre potentiel et/ou les forces et faiblesses que nous avons à travailler dans notre vie.</p>
                        <h3>Qualités</h3>
                        {ange && ange.qualites.split(';').map(i => {return (<p className={classes.p}>{i}</p>)})}
                        <h3>Distorsions</h3>
                        {ange && ange.distorsions.split('-').map(i=> {return(<p className={classes.p}>{i}</p>)})}
                        <h3>Situations</h3>
                        {ange && ange.situations.split(';').map(i=> {return(<p className={classes.p}>{i}</p>)})}
                    </TabPanel>
                </div>
            </Paper>
        </div>
    )
}

export default Resultat